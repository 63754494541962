import { ourServicesNavigation } from "../../components/Constants";

export const IOTFarmManagementObj = {
    navSeq: ourServicesNavigation[0].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IotFarmManagement-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IotFarmManagement-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IotFarmManagement-MR.png",
    bottomImages: ["https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B1.png", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B2.png", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B3.png", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B4.png"]
};
export const CropAssessmentObj = {
    navSeq: ourServicesNavigation[1].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/cropAssessment-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/cropAssessment-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/cropAssessment-MR.png"
};
export const IntelGoodSettlementServicesObj = {
    navSeq: ourServicesNavigation[2].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IntelliGAS-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IntelliGAS-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IntelliGAS-MR.png"
};
export const InputOutputMarketplaceObj = {
    navSeq: ourServicesNavigation[3].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IaOMarketplace-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IaOMarketplace-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IaOMarketplace-MR.png"
};
export const AgriFintechObj = {
    navSeq: ourServicesNavigation[4].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/agriFintech-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/agriFintech-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/agriFintech-MR.png"
};