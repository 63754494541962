import React, { Fragment, useState } from 'react';
import QuickLinksTemp from '../../components/QuickLinksTemplate/QuickLinksTemp';
import { TermsOfUseObj } from "./QuickLinksConstants";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";
import DiscloseWTextSubMenu from "../../components/ReusableGeneric/DiscloseWTextSubMenu";

const definitionAndInterpretationTable = () => {
    return (
        <div>
            <table className="w-full table-fixed">
                <tbody className='border-separate '>
                    <tr>
                        <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Acceptance</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>shall have the meaning given to it at Clause 5.2 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Affiliates</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>in relation to any person, such person’s officers, employees, directors, shareholders, parents, relatives, subsidiaries, holding companies, associates, agents, licensors, licensees and/or licensors;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>agribazaar</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means Star Agribazaar Technology Private Limited, including any of its successors and permitted assigns;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>App</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means the mobile application of agribazaar available through <a href={TermsOfUseObj.googleplayLink} target="_blank" className='text-blue-600 font-medium'>Google Playstore</a> and <a href={TermsOfUseObj.playStoreLink} target="_blank" className='text-blue-600 font-medium'>App Store</a>, including any updates and version changes thereto, from time to time;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Auction Terms</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to in the Preamble and it is set out at Part B of these Terms of Use and may be accessed on the following link (Auction Terms);</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Authorised Representative</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 2.3.2 of Part A of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"Bidding" or "Bid"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it at Clause 3.2 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Buyer</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means an User who lists an auction on the Platform for buying Commodities;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Commodities</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 1.1;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Company Warranties</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means representations and warranties given by a corporate User, which are set out at Clause 9 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Company</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means Star Agribazaar Technology , including any of its successors and permitted assigns;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Confidential Information</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 12.2 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Contract Confirmation</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 5.3 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Contract Confirmation</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means the terms of an auction agreed between a Buyer and a Seller; [as set out in the prescribed format appended at Part D of these Terms;]</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Dispute</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means the terms of an auction agreed between a Buyer and a Seller; [as set out in the prescribed format appended at Part D of these Terms;]</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Intellectual Property</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means: (a) any invention (whether patentable or unpatentable and whether or not reduced to practice), any improvement thereto, and any patent, patent application, and patent disclosure, together with any reissuance, continuation, continuation-in-part, revision, extension, and re-examination thereof; (b) any trademark, service mark, trade dress, logo, trade name, and corporate name, together with any translation, adaptation, derivation, and combination thereof and including any goodwill associated therewith, and any application, registration, and renewal in connection therewith; (c) any copyrightable work, any copyright, and any application, registration, and renewal in connection therewith; (d) any mask works and any application, registrations, and renewals in connection therewith; (e) any trade secret and confidential business information (including any idea, research and development, know-how, formula, compositions, manufacturing and production process and technique, technical data, design, drawing, specification, customer and supplier lists, pricing and cost information, and business and marketing plans and proposals); (f) any computer Software (including data and related documentation), databases, programming, codes and schemas; (g) any other proprietary right; (h) any copies and tangible embodiments thereof (in whatever form or medium); (i) any license or sublicense of an Intellectual Property right, whether exclusive or non-exclusive to us; (j) internet domain name registrations and rights; and (k) any Software, features, design, programming, application, development work and/or promotion, advertising which in any way contributes/supports, tests, helps the business of agribazaar whether developed by a third party or employees of agribazaar or outsourced by us;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Listing Auctions</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 2.1 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Offer</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 5.1 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Order Margin</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 2.2.10 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"Our" or "our"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means Star Agribazaar Technology , including any of its successors and permitted assigns;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Personal Information</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to in Part C of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Platform</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 1.1 of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Privacy Policy</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to in the Preamble and it is set out at Part C of these Terms of Use and may be accessed on the following link [Link to Part C];</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Rules</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to in Clause 14.2 of Part A of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Sanctions</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means any laws, regulations, decrees, ordinances, prohibitions, orders, demands, requests, rules or requirements of the other countries that is applicable relating to trade sanctions, foreign trade controls, export controls, non-proliferation, antiterrorism and similar laws;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Seller</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means an User who lists an auction on the Platform for selling Commodities;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Service Agreement</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means the agreement between agribazaar and each of the corporate clients of agribazaar, which are registered as Users on the Platform, for carrying out Auctions on the Platform as a Buyer or a Seller, as the case may be;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Service Agreement</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 7 of Part B of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Service Fees</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 4 of Part A of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Services</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in Clause 1.1 of Part A of these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Site</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means the agribazaar website at <a href="www.agribazaar.com" target="_blank" className='text-blue-600  font-medium'>www.agribazaar.com</a>, including any updates, changes, modifications, etc., thereto, from time to time;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Software</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means any and all computer programs in both source and object code form, including all modules, routines and sub routines thereof and all related source and other preparatory materials, including user requirements, functional specifications and programming specifications, ideas, principles, programming languages, algorithms, flow charts, logic, logic diagrams, orthographic representations, file structures, coding sheets and coding, including any manuals or other documentation relating thereto and computer generated works;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"Terms" or "Terms of Use"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>has the meaning given to it in the Preamble to these Terms;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"Us" or "us"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means Star Agribazaar Technology Private Limited, including any of its successors and permitted assigns;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>User Content</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means any content that the Users (including you) share, communicate, upload or make available by entering information in the Platform and/or provide to us while registering for or availing any Services. User Content includes communications with other Users and with agribazaar, plus any links, personal information, images, videos, and information provided by Users on User profiles;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"User" or "user"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means any individual or incorporated/registered entity (along with its authorized representatives), which is registered on the Platform in accordance with these Terms, and which shall include any Buyers or Sellers;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"We" or "we"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means Star Agribazaar Technology Private Limited, including any of its successors and permitted assigns;</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>Working Day</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means a day (other than a Saturday, Sunday or a public holiday in the Republic of India) when banks are open for business; and</td>
                    </tr>
                    <tr>
                        <td className='text-sm break-words p-1 pl-2 w-[20%] border-2 border-[#F7D39D]'>"You" or "you"</td>
                        <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>means the User.</td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}
export default function TermsOfUse() {
    const [activeComponent, setactiveComponent] = useState("preamble");
    const [isManualCloseMenu, setisManualCloseMenu] = useState(true);
    const handleTitleClick = (refName) => {
        setactiveComponent(refName);
        window.scrollTo(0, 70);
    }
    const scrollToTopBtn = () => {
        return (
            <button
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="animate-bounce hover:animate-none inline-block p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 hidden active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out  bottom-5 right-5 fixed"
                id="btn-back-to-top"
                onClick={backToTop}
            >
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    className="w-4 h-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                >
                    <path
                        fill="currentColor"
                        d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
                    ></path>
                </svg>
            </button>
        )
    }
    let mybutton = document.getElementById('btn-back-to-top');
    window.onscroll = function () {
        scrollFunction();
    };
    const scrollFunction = () => {
        if ( document.documentElement.scrollTop > 600) {
            mybutton.style.display = 'block';
        } else {
            mybutton.style.display = 'none';
        }
    }
    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const handleTitleClickMobile = (refName) => {
        setactiveComponent(refName);
        setTimeout(() => { openChild(refName) }, 0);
        setisManualCloseMenu(false);
    }
    const handleSubtopicClick = (e) => {
        const subTopicIdCode = e.target.id;
        if (subTopicIdCode[0] === 'A') openParentThenChild("partA", subTopicIdCode);
        else if (subTopicIdCode[0] === 'B') openParentThenChild("partB", subTopicIdCode);
        else if (subTopicIdCode[0] === 'C') openParentThenChild("partC", subTopicIdCode);
        else if (subTopicIdCode[0] === 'D') openParentThenChild("partD", subTopicIdCode);
    }
    const openParentThenChild = (parentRefName, childSubTopicIdCode) => {
        setactiveComponent(parentRefName);
        setTimeout(() => { openChild(childSubTopicIdCode) }, 0);
    }
    const openChild = (childSubTopicIdCode) => {
        const targetSubtopic = document.getElementById(`content${childSubTopicIdCode}`);
        targetSubtopic.scrollIntoView({
            'behavior': "smooth",
            'block': 'start'
        });
    }
    const headerContent = () => {
        return (
            <div className='text-[#3B3939] font-medium text-3xl sm:text-4xl text-center sm:text-left px-5 sm:px-12 py-6'>
                <p>Star agribazaar technology limited terms of use</p>
                <hr className="hidden sm:block mt-6" />
            </div>
        )
    }
    //left menu table of contents
    const generalTermsContent = () => {
        return (
            <div className='text-[#3B3939]' onClick={(e) => handleSubtopicClick(e)}>
                <ul className="list-disc list-inside marker:text-[#ed1849] marker:text-lg leading-loose">
                    <li className='hover:underline cursor-pointer' id="A1">Introduction</li>
                    <li className='hover:underline cursor-pointer' id="A2">User of the platform and services</li>
                    <li className='hover:underline cursor-pointer' id="A3">Content on the platform and/or relating to the services</li>
                    <li className='hover:underline cursor-pointer' id="A4">Service Fees</li>
                    <li className='hover:underline cursor-pointer' id="A5">Your limited license to the platform, services and the content</li>
                    <li className='hover:underline cursor-pointer' id="A6">Links</li>
                    <li className='hover:underline cursor-pointer' id="A7">Acceptable use of the platform and services</li>
                    <li className='hover:underline cursor-pointer' id="A8">Termination and Survival</li>
                    <li className='hover:underline cursor-pointer' id="A9">Your Indemnity</li>
                    <li className='hover:underline cursor-pointer' id="A10">Disputes among users</li>
                    <li className='hover:underline cursor-pointer' id="A11">Disclaimers</li>
                    <li className='hover:underline cursor-pointer' id="A12">Privacy</li>
                    <li className='hover:underline cursor-pointer' id="A13">Third party fees</li>
                    <li className='hover:underline cursor-pointer' id="A14">Governing law and jurisdiction</li>
                    <li className='hover:underline cursor-pointer' id="A15">Other Provisions</li>
                    <li className='hover:underline cursor-pointer' id="A16">Contact/Notice</li>
                </ul>
            </div>
        )
    }
    const termsAndConditionsContent = () => {
        return (
            <div className='text-[#3B3939]' onClick={(e) => handleSubtopicClick(e)}>
                <ul className="list-disc list-inside marker:text-[#ed1849] marker:text-lg leading-loose">
                    <li className='hover:underline cursor-pointer' id="B1">Introduction</li>
                    <li className='hover:underline cursor-pointer' id="B2">Listing auctions</li>
                    <li className='hover:underline cursor-pointer' id="B3">Bidding for the auctions</li>
                    <li className='hover:underline cursor-pointer' id="B4">Withdrawal or retraction of an auction</li>
                    <li className='hover:underline cursor-pointer' id="B5">Offer and acceptance</li>
                    <li className='hover:underline cursor-pointer' id="B6">The limited role of agribazaar and the obligations between the buyer and sellers</li>
                    <li className='hover:underline cursor-pointer' id="B7">The transfer of title and risk in relation to a trade through the platform</li>
                    <li className='hover:underline cursor-pointer' id="B8">Dispute regarding particulars of an auction</li>
                    <li className='hover:underline cursor-pointer' id="B9">Representations and warranties given by a corporate user relating to its use of the platform</li>
                    <li className='hover:underline cursor-pointer' id="B10">Indemnification under the service agreement</li>
                    <li className='hover:underline cursor-pointer' id="B11">Limitaions of liability, disclaimer of warranties and general release of agribazaar under the service agreements</li>
                    <li className='hover:underline cursor-pointer' id="B12">Use of information, intellectual property and confidentiality</li>
                    <li className='hover:underline cursor-pointer' id="B13">Relationship between parties</li>
                    <li className='hover:underline cursor-pointer' id="B14">Service Fees</li>
                </ul>
            </div>
        )
    }
    const privacyPolicy = () => {
        return (
            <div className='text-[#3B3939]' onClick={(e) => handleSubtopicClick(e)}>
                <ul className="list-disc list-inside marker:text-[#ed1849] marker:text-lg leading-loose">
                    <li className='hover:underline cursor-pointer' id="C1">Collection</li>
                    <li className='hover:underline cursor-pointer' id="C2">agribazaar's use and disclosure of information</li>
                    <li className='hover:underline cursor-pointer' id="C3">Security of your personal information</li>
                    <li className='hover:underline cursor-pointer' id="C4">Access to personal information</li>
                    <li className='hover:underline cursor-pointer' id="C5">Term</li>
                    <li className='hover:underline cursor-pointer' id="C6">Links</li>
                    <li className='hover:underline cursor-pointer' id="C7">General terms</li>
                    <li className='hover:underline cursor-pointer' id="C8">Changes to this privacy policy</li>
                    <li className='hover:underline cursor-pointer' id="C9">Contact</li>
                </ul>
            </div>
        )
    }
    const sampleContractNote = () => {
        return (
            <div className='text-[#3B3939]' onClick={(e) => handleSubtopicClick(e)}>
                <ul className="list-disc list-inside marker:text-[#ed1849] marker:text-lg leading-loose">
                    <li className='hover:underline cursor-pointer' id="D1">Auction Contract Note – Sell side</li>
                    <li className='hover:underline cursor-pointer' id="D2">Auction Contract Note – Buy Side</li>
                </ul>
            </div>
        )
    }
    //left menu parent title
    const tableOfContents = () => {
        return (
            <Fragment>
                <DiscloseWTextSubMenu title="PREAMBLE" content={false} handleTitleClick={() => handleTitleClick("preamble")} handleTitleClickMob={() => handleTitleClickMobile("preamble")} />
                <DiscloseWTextSubMenu title="DEFINITIONS AND INTERPRETATION" content={false} handleTitleClick={() => handleTitleClick("defAndInter")} handleTitleClickMob={() => handleTitleClickMobile("defAndInter")} />
                <DiscloseWTextSubMenu title="PART A - General Terms" content={generalTermsContent()} handleTitleClick={() => handleTitleClick("partA")} handleTitleClickMob={() => handleTitleClickMobile("partA")} />
                <DiscloseWTextSubMenu title="PART B - Terms and Conditions for Auction on the platform" content={termsAndConditionsContent()} handleTitleClick={() => handleTitleClick("partB")} handleTitleClickMob={() => handleTitleClickMobile("partB")} />
                <DiscloseWTextSubMenu title="PART C - Privacy Policy" content={privacyPolicy()} handleTitleClick={() => handleTitleClick("partC")} handleTitleClickMob={() => handleTitleClickMobile("partC")} />
                <DiscloseWTextSubMenu title="PART D - Sample Contract Note" content={sampleContractNote()} handleTitleClick={() => handleTitleClick("partD")} handleTitleClickMob={() => handleTitleClickMobile("partD")} />
            </Fragment>
        )
    }
    const preambleContent = () => {
        return (
            <div>
                <h4 className='text-[#4165b3] text-xl font-semibold' id="contentpreamble">PREAMBLE</h4>
                <div className='pt-2 font-normal text-md leading-relaxed'>
                    agribazaar is an online marketplace where registered users can buy and sell various agricultural commodities by means of flexible forward or reverse auctions. The users of agribazaar can list customized auctions, based on their specific quality and quantity requirements, in relation to the agricultural products (or any other tradeable goods which may be permitted by agribazaar to be traded on the Platform) they wish to sell or purchase on the online marketplace. agribazaar manages the entire process of sale and purchase, including but not limited to the processes of listing, selling, buying, settlement and processing of payments, etc. agribazaar also publishes information, links and opinions relating to the agricultural industry and the market.
                    <br /><br /> Access to and use of the entire agribazaar ecosystem, including its website, mobile applications, digital products and services, plug-ins, etc., available on its platforms are governed by the terms and conditions set out herein below in this document ("Terms of Use" or "Terms"). These Terms of Use of agribazaar include the terms and conditions relating to: (a) the access to and use of agribazaar’s website and mobile application ("General Terms"); and (b) the rules of auction of agricultural commodities on agribazaar’s online platform ("Auction Terms") and agribazaar’s privacy policy ("Privacy Policy").
                </div>
            </div>
        )
    }
    const defAndInterContent = () => {
        return (
            <div className='pt-0'>
                <h4 className='text-[#4165b3] text-xl font-semibold' id="contentdefAndInter">DEFINITIONS AND INTERPRETATION</h4>
                <div className='pt-2 font-normal text-md leading-relaxed'>
                    In these Terms, unless the context requires otherwise the following words and expressions shall have the following meanings:
                </div>
                <div className='pt-4'>
                    {definitionAndInterpretationTable()}
                </div>
                <h2 className='pt-4 font-semibold'>Unless the context requires otherwise:</h2>
                <p className='pt-2'>(a) Headings are for ease of reference only and shall not affect the meaning or interpretation of the Terms;</p>
                <p className='pt-2'>(b) Words in the singular shall include the plural and vice versa; and</p>
                <p className='pt-2'>(c) The words "include" and "including" shall be construed without limitation.</p>
            </div>
        )
    }
    const partAContent = () => {
        return (
            <div id="contentpartA" className='pt-0 px-2'>
                <h4 className='text-[#4165b3] text-xl font-semibold'>
                    PART A - GENERAL TERMS
                </h4>
                <div className='pt-2 font-normal text-md leading-relaxed'>
                    <ul className='list-decimal font-medium text-left'>
                        <li id="contentA1">
                            INTRODUCTION
                            <div className='font-normal leading-loose'>
                                <p><strong>1.1</strong> This Part A sets out the general terms which govern your access to and use of the following:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> the agribazaar website (www.agribazaar.com) (the "Site");</p>
                                        <p><strong className='text-sm'>(b)</strong> the agribazaar mobile application (the "App") (collectively, (a) and (b) are referred to herein after as the "Platform" which also includes or will include any other medium through which agribazaar chooses to offer its products and services to its users/customers);</p>
                                        <p><strong className='text-sm'>(c)</strong> the online applications and digital products made available on the Platform, where the User can enter into an online transaction for the sale and purchase of agricultural commodities or any other tradeable goods which may be permitted by agribazaar to be traded on the Platform (such agricultural commodities or tradeable goods, "Commodities"); and</p>
                                        <p><strong className='text-sm'>(d)</strong> any other services offered or may be offered by agribazaar -- directly or indirectly -- through any of its Affiliates or third parties, via any medium ("Additional Services") (collectively, (a), (b), (c) and (d) above are herein after referred to as the "Services").</p>
                                    </div>
                                </p>
                                <p><strong>1.2</strong> For avoidance of doubt, agribazaar provides the Platform and/or the Services for facilitating the sale and purchase of the Commodities between the Buyers and the Sellers. agribazaar is neither a Seller nor a Buyer of any Commodities on the Platform.</p>
                                <p><strong>1.3</strong> By using the platform or the services, including by registering as a user, you (on behalf of yourself and the business you represent) are confirming that you accept and agree to these terms and are entering in this legally binding contract, as amended from time to time, and that you shall comply with and be bound by these terms. If you do not accept or agree to comply with and be bound by these terms, you must not access or use the platform and/or the services.</p>
                                <p><strong>1.4</strong> Please read these terms carefully now and from time to time for any updates. We recommend that you print a copy of these terms, and any future versions, for your reference.</p>
                                <p><strong>1.5</strong> These Terms refer to and include the following additional policies and/or terms, which shall also apply to you and your access to and use of the Platform and/or the Services:
                                    <div className='pl-4'>
                                        <p><strong>1.5.1</strong> OUR PRIVACY POLICY, to be found at Part C of these Terms, which is available at [Link to Part C] and sets out the terms on which we process and hold data collected from you which you provide to agribazaar while signing up and using the Platform and/or Services. By using the Platform and/or the Services, you consent to the processing of your data in accordance with our Privacy Policy. In this regard, you also represent and warrant that all data provided by you to us is true, accurate, correct, complete and not misleading; and</p>
                                        <p><strong>1.5.2</strong> AUCTION TERMS, to be found at Part B of these Terms, which is available at [Link to Part B]and sets out the terms and conditions relating to the auction process on the Platform and/or the Services for buying and selling Commodities.</p>
                                    </div>
                                </p>
                                <p><strong>1.6</strong> We may amend or make changes to these terms and/or any policy from time to time.</p>
                                <p><strong>1.7</strong> We may amend these Terms and/or any policy from time to time. If we change these Terms and/or policies, we will give you notice via e mail as soon as practicable prior to the implementation of such changes and the posting of the revised Terms and/or policy on the Platform, except in such cases in which the changes are required by the law, or by a governmental or judicial authority; in which event, the notice will be given after posting the revised Terms and/or policy on the Platform. Any such changes will come into effect on the revision date stated in the revised Terms and/or policies. By continuing to access or use the Platform and/or the Services, you are or will be agreeing to the revised Terms and/or policies.</p>
                                <p><strong>1.8</strong> We recommend that you read the Terms and/or policies to ensure you understand the Terms that apply to you at any time you access, use or transact business via the Platform or avail our Services.</p>
                            </div>
                        </li>
                        <li id="contentA2">
                            USERS OF THE PLATFORM AND SERVICES
                            <div className='font-normal leading-loose'>
                                <p><strong>2.1</strong> The Platform and the Services are addressed, and made available, to the Users.</p>
                                <p><strong>2.2</strong>  Any person or entity wanting to access or use the Platform shall first need to register on the Platform to become a User of the Platform and/or the Services.</p>
                                <p>
                                    <strong>2.3</strong> To become a User and participate in the Platform and/or avail the Services, a person shall be required to submit specified details (as may be amended from time to time) to meet the registration requirements as listed on the Platform, which requirements shall include:
                                    <div className='pl-4'>
                                        <p><strong>2.3.1</strong> For individuals/proprietorship firms: a PAN card or an Aadhar card (as applicable), a contact number, an e mail address, copy of a cancelled bank cheque and any other information or documents needed by us at the time of registration;</p>
                                        <p><strong>2.3.2</strong> For incorporated/registered entities: a PAN card, details of the nature of the entity (e.g, LLP, company, etc.), copy of the GST registration certificate, copy of the certificate of incorporation or registration (as applicable), charter documents, copy of a cancelled bank cheque , address proof of the entity and contact number(s) of the entity registering to become a User.</p>
                                        <p>Registration shall be completed and submitted only by an authorized representative or any other person with legal authority to bind the entity which is applying to become a User ("Authorized Representative") and such persons shall be required to provide their name, Aadhar card copy or any government issued identity proof, contact number, e mail address and a copy of the authorizing board resolution, power of attorney or any other document on the letterhead of the entity conferring the authority on the individual to act on behalf of the entity in relation to the Platform and/or the Services.</p>
                                    </div>
                                </p>
                                <p><strong>2.4</strong>  In addition to the details required under clause 2.3 above, agribazaar may require any individual or entity wishing to become a User for
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> providing further documentation for ‘know your customer’ purposes;</p>
                                        <p><strong className='text-sm'>(b)</strong> verifying the existence and capacity of a corporate entity registering to become a User; and/or</p>
                                        <p><strong className='text-sm'>(c)</strong> verifying the authority of the Authorized Representative acting on behalf of the entity wishing to become a User.</p>
                                    </div></p>
                                <p><strong>2.5</strong> agribazaar shall have the sole and absolute discretion whether to accept an individual or corporate entity as a User. agribazaar reserves the right to reject any application for registration, without giving reasons or cause.</p>
                                <p><strong>2.6</strong> agribazaar reserves the right to deny any User access to the Platform and/or the Services, without giving reasons or cause.</p>
                                <p><strong>2.7</strong> On receipt of all the details required under clause 2.3 and clause 2.4 above to the satisfaction of agribazaar, and subject always to agribazaar’s rights under clause 2.5 above, agribazaarwill provide a username and a password to the User.</p>
                                <p>
                                    <strong>2.8</strong>  By registering with the Platform, each User represents and warrants that:
                                    <div className='pl-4'>
                                        <p><strong>2.8.1</strong> in case of individuals, you are over 18 years of age;</p>
                                        <p><strong>2.8.2</strong> you have the authority and/or the relevant authorizations required to register and operate on the Platform and/or avail the Services, as applicable;</p>
                                        <p><strong>2.8.3</strong> you have complied, and shall continue to comply, with all applicable law to access and use the Platform and/or avail the Services; and</p>
                                        <p><strong>2.8.4</strong> any information provided by you is true, accurate, correct, complete and not misleading.</p>
                                    </div>
                                </p>
                                <p><strong>2.9</strong> A User will be provided with one username and password.</p>
                                <p><strong>2.10</strong> Users must update agribazaar of any changes in their personal or corporate information immediately following any such change.</p>
                                <p><strong>2.11</strong> Users shall keep their username and password confidential, safe and must not disclose it to any third party. Any unauthorized use of the User’s username and password shall be attributable to the User and it shall not be the responsibility of agribazaar. Any losses arising due to such use shall be borne by the User alone.</p>
                                <p><strong>2.12</strong> If you know or suspect that your username or password may have been compromised or made public, you shall notify agribazaar immediately at the address specified below so that we may take any appropriate steps, including cancelling any compromised or potentially compromised username or password and providing new login details.</p>
                                <p><strong>2.13</strong> agribazaar shall have the right to disable any username or password at any time, if, in our opinion, you have failed to comply with any of the provisions of these Terms.</p>
                                <p><strong>2.14</strong> You are solely responsible for any actions of an unauthorized user using your username or password on the Platform and/or for availing the Services.</p>
                            </div>
                        </li>
                        <li id="contentA3">
                            CONTENT ON THE PLATFORM AND/OR RELATING TO THE SERVICES
                            <div><i>Your use of the Platform and/or the Services and the User Content is at your sole risk</i></div>
                            <div className='font-normal leading-loose'>
                                <p><strong>3.1</strong> User Content, whether publicly posted or privately transmitted, is the sole responsibility of the Users who originate such User Content. You understand and acknowledge that you are solely responsible for any User Content you provide to or use on the Platform and for any consequences thereof.</p>
                                <p><strong>3.2</strong> agribazaar is under no obligation to review or monitor any User Content, although we may do so from time to time at our sole discretion. agribazaar has no obligation to protect any User Content.</p>
                                <p><strong>3.3</strong> You agree. represent and warrant that your User Content does not infringe the intellectual property rights, privacy rights, publicity rights or other legal rights of any third party.</p>
                                <p><strong>3.4</strong> We may refuse to accept or transmit User Content for any reason at our sole discretion.</p>
                                <p><strong>3.5</strong> We may remove User Content from the Platform and/or any portal where the Services are provided for any reason at our sole discretion.</p>
                                <div><i>Ownership of all other content</i></div>
                                <p><strong>3.6</strong> Other than User Content, agribazaar owns, or holds licenses to, all rights, title, and interest in the Intellectual Property in relation to the Platform and/or Services.</p>
                                <p><strong>3.7</strong> Our Intellectual Property relating to the Platform and the Services are all protected under the applicable copyright, trademark and other applicable laws. All rights in relation to our Intellectual Properties are reserved. You shall not duplicate, copy, or reuse our Intellectual Property, or any portion of the HTML/CSS, JavaScript source code, programming or visual design elements or concepts without express written permission from agribazaar.</p>
                                <div><i>Advertising content</i></div>
                                <p><strong>3.8</strong> The Platform and/or any portal where any of the Services are provided by agribazaar may include advertisements, which may be targeted to the content or information on the Platform (including User Content), questions raised through the Platform and/or while using the Services or any other information in relation thereto. The types and extent of advertising by agribazaar on the Platform are at the sole discretion of agribazaar and are subject to changes from time to time.</p>
                                <div><i>Content removal; cooperation with law enforcement</i></div>
                                <p><strong>3.9</strong> agribazaar shall have the right:</p>
                                <div className='pl-4'>
                                    <p><strong>3.9.1</strong> to block, remove or refuse to distribute any content or User Content for any reason or no reason in our sole discretion;</p>
                                    <p><strong>3.9.2</strong> to take any action with respect to any content or User Content that we deem necessary or appropriate in our sole discretion, including if we believe that such content or User Content:
                                        <div className='pl-4'>
                                            <p><strong className='text-sm'>(a)</strong> violates these Terms; </p>
                                            <p><strong className='text-sm'>(b)</strong> it infringes any Intellectual Property right or other right of any person or entity; </p>
                                            <p><strong className='text-sm'>(c)</strong> threatens the personal safety of the Users of the Platform and/or the Services or the safety and security of the general public; or</p>
                                            <p><strong className='text-sm'>(d)</strong> creates any liability or threatens to create any liability for agribazaar;</p>
                                        </div>
                                    </p>
                                    <p><strong>3.9.3</strong> subject to the terms of our Privacy Policy and any applicable law to disclose any information provided by a User to agribazaar, including any personal information, to any third party who claims that User Content provided by you has violated that third party’s rights, including that third party’s intellectual property rights or its right to privacy, provided that we shall send prior notification to the User if practicable;</p>
                                    <p><strong>3.9.4</strong> to take any appropriate legal action, including disclosing your personal information or other information about you to any law enforcement body, government department, court of law, regulatory agency, tribunal or any law enforcement or government official, in respect of any suspected illegal or unauthorized use of the Platform and/or the Services;</p>
                                    <p><strong>3.9.5</strong> to terminate or suspend your access to all or part of the Platform and/or the Services, and to reclaim and redistribute usernames, for any or no reason, including without limitation, for any violation of these Terms;</p>
                                    <p>
                                        <strong>3.9.6</strong> without prejudice to the above, to fully cooperate with any law enforcement or regulatory authority or court order requesting or directing agribazaar to disclose the identity, or other information, of anyone posting or distributing any User Content on or through the Platform and/or the Services. We also reserve the right to access, review, monitor, display, read, preserve, store and disclose any information and any User Content as we reasonably believe is necessary or appropriate to:
                                        <div className='pl-4'>
                                            <p>(i) satisfy any applicable law, regulation, legal processor governmental request;</p>
                                            <p>(ii) investigate potential violations of and/or enforce these Terms;</p>
                                            <p>(iii) detect, prevent, or otherwise address fraud, security or technical issues, or</p>
                                            <p>(iv) protect the rights, property or safety of any Users, agribazaar or any third parties.</p>
                                        </div>
                                    </p>
                                    <p><strong>3.9.7</strong> You waive and hold harmless agribazaar and/or its affiliates from any claims resulting from any of the actions or activities listed in this clause 3.9, or action we or our affiliates take during or as a result of any investigations.</p>
                                    <p><strong>3.9.8</strong> We assume no liability for any action or inaction regarding transmissions, communications or User Content provided by any User of the Platform and/or the Services or by any third party. We have no liability or responsibility to any person for the performance or non-performance of the activities described in this Clause.</p>
                                </div>
                            </div>
                        </li>
                        <li id="contentA4">
                            SERVICE FEES
                            <div className='font-normal leading-loose'>
                                <p><strong>4.1</strong> Users shall pay a technological transaction fee to agribazaar for the use of the Platform and/or the Services, as applicable ("Service Fees").</p>
                                <p><strong>4.2</strong> The Service Fees may be calculated based on a fixed fee (on an annual basis or on a per-transaction basis) for use of the Platform or by any other method considered appropriate by agribazaar.</p>
                                <p><strong>4.3</strong> For the details of the terms relating to applicable Service Fees, please refer to Clause 14 of Part B.</p>
                            </div>
                        </li>
                        <li id="contentA5">
                            YOUR LIMITED LICENSE TO THE PLATFORM, SERVICES AND THE CONTENT
                            <div className='font-normal leading-loose'>
                                <p><strong>5.1</strong> Subject to and conditional upon your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable license to access and view the Platform, User Content and our Intellectual Property solely in connection with your permitted use of the Platform and/or the Services.</p>
                                <p><strong>5.2</strong> You shall not download, capture, save, distribute or broadcast any of our Intellectual Property or any other person’s User Content. You shall only download, capture, and/or save your User Content while only using the tools we make available for such purpose through the Platform. You also cannot use derivative works of your User Content outside the Platform without the express prior written permission from agribazaar.</p>
                                <p>
                                    <strong>5.3</strong> Except as expressly permitted in these Terms, you shall not:
                                    <div className='pl-4'>
                                        <p><strong>5.3.1</strong> copy, modify, or create derivative works based on the Platform and/or the Services;</p>
                                        <p><strong>5.3.2</strong> distribute, transfer, sublicense, lease, lend, or rent the Platform and/or the Services to any third party;</p>
                                        <p><strong>5.3.3</strong> reverse engineer, decompile, or disassemble the Platform and/or the Services; and/or</p>
                                        <p><strong>5.3.4</strong> make the functionality of the Platform or the Services available to multiple Users through any means. We reserve all rights in and to the Platform and the Services.</p>
                                    </div>
                                </p>
                            </div>
                        </li>
                        <li id="contentA6">
                            LINKS
                            <div className='font-normal leading-loose'>
                                <p><strong>6.1</strong> The Platform and the Services may contain links to other websites and online resources provided by third parties. A link to a third party’s website is provided for information only and it does not mean that we approve, endorse or are affiliated to that website/portal or approve of the information contained therein. We have no control over and are not responsible for the content of such websites/portals or any information provided by third parties. We shall not be liable for any damage or loss caused to you or your business, from your use of any third-party websites, portals or information. You shall be responsible for always reading the terms and conditions and the privacy policy of such a third-party websites or portals, before using them.</p>
                            </div>
                        </li>
                        <li id="contentA7">
                            ACCEPTABLE USE OF THE PLATFORM AND SERVICES
                            <div className='font-normal leading-loose'>
                                <p>
                                    <strong>7.1</strong> You are responsible for your use of the Platform and the Services, and for any use of the Platform or the Services made using your account. Our goal is to create a positive, useful, and safe user experience. To promote this goal, we prohibit certain kinds of conduct or User Content that may be harmful to other Users, agribazaar or the general public. When you use the Platform and/or the Services, you shall not:
                                    <div className='pl-4'>
                                        <p><strong>7.1.1 </strong>violate any law or regulation;</p>
                                        <p><strong>7.1.2 </strong>violate, infringe or misappropriate any person’s intellectual property, safety, health, privacy, reputation or other legal or natural rights;</p>
                                        <p><strong>7.1.3 </strong>upload, communicate, transmit or otherwise make available any content that is or could reasonably be viewed as unlawful, harmful, harassing, defamatory or otherwise objectionable, pornographic, obscene, indecent, invasive of another’s privacy, hateful, likely to incite violence or racial or ethnic hatred, or otherwise objectionable;</p>
                                        <p><strong>7.1.4 </strong>send unsolicited or unauthorized advertising or commercial communications, such as spam, forge any TCP/IP packet header or any part of the header information in any e mail or posting, or in any way use the Platform or the Services to send altered, deceptive, or false source identifying information;</p>
                                        <p><strong>7.1.5 </strong>engage in 'spidering' or 'harvesting' or participating in the use of Software, including spyware, designed to collect data from the Platform or Services;</p>
                                        <p><strong>7.1.6 </strong>collect any information about or regarding other Users including but not limited to any personal data or information;</p>
                                        <p>
                                            <strong>7.1.7 </strong>interfere with or disrupt the smooth functioning of the Platform or interfere with, or disrupt,
                                            <div className='pl-4'>
                                                <p><strong className='text-sm'>(a)</strong> the Services,</p>
                                                <p><strong className='text-sm'>(b)</strong> other Users’ access, or</p>
                                                <p><strong className='text-sm'>(c)</strong> the servers or networks through which the Platform or the Services are provided;</p>
                                            </div>
                                            <div className='pl-4'>
                                                <p><strong>7.1.7A</strong> A transmit any viruses or other computer instructions or technological means whose purpose is to disrupt, damage, or interfere with the use of computers or related systems in connection with the Platform and/or the Services;</p>
                                            </div>
                                        </p>
                                        <p><strong>7.1.8 </strong>stalk, harass or harm any individual connected to the Platform and/or the Services, including other Users or the personnel of agribazaar;</p>
                                        <p><strong>7.1.9 </strong>impersonate any person or entity (including other Users or the personnel of agribazaar) or perform any other similar fraudulent activity, such as phishing;</p>
                                        <p><strong>7.1.10</strong> use any means to 'scrape', 'crawl' or 'hack' any web pages relating to the Platform and/or Services and/or agribazaar;</p>
                                        <p><strong>7.1.11</strong> probe, scan, crack, track and/or test the vulnerability of any system or network, attempt to circumvent any technological measure or authentication measures implemented to protect the Platform and/or the Services by agribazaar or any of our providers or any other third party (including another User);</p>
                                        <p><strong>7.1.12</strong> access or search or attempt to access or search the Platform or the Services by any means (automated or otherwise) other than through the formats or interfaces prescribed, published or provided by agribazaar on its Platform, and such access or search or attempt to access or search the Platform shall only be pursuant to these Terms;</p>
                                        <p><strong>7.1.13</strong> attempt to decipher, decompile, disassemble, or reverse engineer any of the Software or other underlying code used to provide the Platform and/or the Services;</p>
                                        <p><strong>7.1.14</strong> use the Platform and/or the Services in any other way not permitted by these Terms; or</p>
                                        <p><strong>7.1.15</strong> advocate, encourage, or assist any third party in doing any of the foregoing.</p>
                                    </div>
                                </p>
                            </div>
                        </li>
                        <li id="contentA8">
                            TERMINATION AND SURVIVAL
                            <div className='font-normal leading-loose'>
                                <p>
                                    <strong>8.1</strong> Use of the Platform and/or the Services is a privilege. We may decline to register you as a User, terminate your registration and/or restrict your access to or usage of the Platform and/or the Services (including via any other aliases you use) at any time at our complete and sole discretion without consulting with you. Notwithstanding Clause 8.1 above, we consider the following as grounds for refusal of access or use of the Platform:
                                    <div className='pl-4'>
                                        <p><strong>8.1.1</strong> if complaints are received about you;</p>
                                        <p><strong>8.1.2</strong> if you breach these Terms and/or any other agribazaar policies;</p>
                                        <p><strong>8.1.3</strong> if you violate or threaten to violate any law;</p>
                                        <p><strong>8.1.4</strong> if you cause or threaten to cause harm to public order, health and safety of the general public or of agribazaar employees or other Users;</p>
                                        <p><strong>8.1.5</strong> if you cause or threaten to cause harm to agribazaar’s business, the Platform or the quality of the Services;</p>
                                        <p><strong>8.1.6</strong> any other reason in the reasonable assessment of agribazaar.</p>
                                    </div>
                                </p>
                                <p><strong>8.2</strong> We reserve the right to not provide you with access to the Platform and/or the Services. We also reserve the right to terminate any User’s right to access the Platform and/or the Services at any time, at our sole discretion, if you violate any of these Terms and/or agribazaar’s policies. Further, if we receive complaints about you, we may terminate your registration and your ability to use the Platform and/or Services, at our sole discretion.</p>
                                <p><strong>8.3</strong> You may end this legally enforceable agreement with agribazaar at any time for any reason, by
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> discontinuing the use of the Platform and/or Services, </p>
                                        <p><strong className='text-sm'>(b)</strong> informing agribazaar about such discontinuation, and </p>
                                        <p><strong className='text-sm'>(c)</strong> requesting us to delete your account by sending an e mail or a letter at the contact details of agribazaar set out in Clause 16 of Part A of these Terms.</p>
                                    </div>
                                </p>
                                <p><strong>8.4</strong> The provisions "Content on the Platform and/or Services", "Acceptable Use of the Platform and Services", "Privacy Policy", "Indemnity", "Termination and Survival", and "Other Provisions" will survive the closure/deletion of your account and any expiration or termination of these Terms, and you will continue to be responsible for all of your activities during the time you used the Platform and/or the Services.</p>

                            </div>
                        </li>
                        <li id="contentA9">
                            YOUR INDEMNITY
                            <div className='font-normal leading-loose'>
                                <p>
                                    <strong>9.1</strong> At any time after your registration, including after the termination of your registration with agribazaar for cause, you agree to indemnify and hold harmless agribazaar, its employees and its Affiliates, from and against any and all claims, costs, proceedings, demands, losses, damages, and expenses (including, without limitation, attorney’s fees, and legal costs) of any kind or nature, arising from or relating to:
                                    <div className='pl-4'>
                                        <p><strong>9.1.1</strong> any actual or alleged breach of these Terms by you or anyone using your account; and/or</p>
                                        <p><strong>9.1.2</strong> your use of the Platform and/or the Services; and/or</p>
                                        <p><strong>9.1.3</strong> the content of your uploaded information; and/or</p>
                                        <p><strong>9.1.4</strong> the negotiation, performance or enforcement of any contract or agreement or understanding between you and other Users via the Platform or through the use of our Services; and/or</p>
                                        <p><strong>9.1.5</strong> the transfer of Commodities and/or money between you and another User.</p>
                                    </div>
                                </p>
                                <p><strong>9.2</strong> The rights and remedies of agribazaar, its employees and/or its Affiliates, in respect of any breach of these Terms, shall not be affected by any act or happening which otherwise might have affected such rights and remedies, except by a specific written waiver by agribazaar, its employees and/or its Affiliates.</p>
                                <p><strong>9.3</strong> The rights of indemnification of agribazaar, its employees and/or its Affiliates hereunder shall be in addition to all other rights available to agribazaar, its employees and/or its Affiliates in law, equity or otherwise, including without limitation rights of specific performance, recession and restitution.</p>
                                <p><strong>9.4</strong> The Users shall not pursue any claim, seek damages, reimbursements or contribution from agribazaar, its employees and/or its Affiliates, in respect of any claim costs, proceedings, demands, losses, damages, and expenses (including, without limitation, reasonable attorney’s fees, and legal costs) of any kind or nature, arising from or relating to the actions of other Users or from their dealings with other Users.</p>
                            </div>
                        </li>
                        <li id="contentA10">
                            DISPUTES AMONG USERS
                            <div className='font-normal leading-loose'>
                                <p><strong>10.1</strong> If a dispute arises between you and another User, you must resolve it in accordance with the applicable law of the jurisdiction agreed upon between the parties to the Service Contract, Contract Note and Acceptance. You hereby agree to release and hold harmless agribazaar and its, employees, directors and Affiliates, from any and all claims, demands, and damages (including, without limitation, actual and consequential) of any kind or nature, indirect or direct, unknown or known, unsuspected or suspected, undisclosed or disclosed, arising out of or in any way connected with any such dispute.</p>
                            </div>
                        </li>
                        <li id="contentA11">
                            DISCLAIMERS
                            <div className='font-normal leading-loose'>
                                <p><strong>11.1</strong> The Platform and/or Services are provided on an "as is" and “as available” basis. To the extent permitted by any applicable law, agribazaar, its employees, directors and affiliates disclaim all warranties, conditions, and representations of any kind whatsoever, whether express, implied, statutory, or otherwise, including those relating to merchantability, fitness for a particular purpose, non-infringement and all warranties, conditions, and representations arising out of course of dealing or usage of trade.</p>
                                <p><strong>11.2</strong> All information provided on the Platform and/or via the Services is for general information purposes only. The information should not be interpreted as individualized advice, investment recommendation and/or farming or agriculture consultation. agribazaar does not offer investment, farming, or business advice or consultations. None of the information found on the Platform, and/or on any of the portals through which any of the Services are provided, should be considered a recommendation, sponsorship, endorsement or any business of any nature and/or any financial derivative of any nature.</p>
                                <p><strong>11.3</strong> agribazaar shall use all reasonable endeavours to ensure that all material and information presented on the Platform, and/or via the portals through which Services are provided, is taken from sources believed to be reliable and all information is presented in good faith, however agribazaar makes no warranty or representation, either expressed or implied, in relation to the correctness, completeness, or accuracy of such information or material.</p>
                                <p>
                                    <strong>11.4</strong> agribazaar will have no liability for any:
                                    <div className='pl-4'>
                                        <p><strong>11.4.1</strong> errors, mistakes, or inaccuracies of content; on the Platform or in relation to the Services;</p>
                                        <p><strong>11.4.2</strong> negligence, breach of contract, warranties, errors, mistakes, or omission of the Users;</p>
                                        <p><strong>11.4.3</strong> injury (personal or otherwise), property damage or loss of data resulting from your access to or use of the Platform and/or the Services;</p>
                                        <p><strong>11.4.4</strong> unauthorized access to or use of the Platform, the Services, our servers or of any personal information, auction data, or User Content;</p>
                                        <p><strong>11.4.5</strong> interruption of transmission to or from the Platform or via the Services, or any deletion of or failure to store or transmit any content or communications;</p>
                                        <p><strong>11.4.6</strong> bugs, viruses, trojan horses, or similar which may be transmitted on or through the Platform or via the services by any third party;</p>
                                        <p><strong>11.4.7</strong> failure of the Platform and/or the Services to meet User expectations or requirements; or</p>
                                        <p><strong>11.4.8</strong> loss or damage of any kind incurred as a result of the use of any content posted or shared through the Platform or the Services. You understand and agree that any material or information downloaded or otherwise obtained using the Platform and/or the Services is done at your own risk and that you shall be solely responsible for any damage arising from doing so. No advice or information, whether oral or written, obtained by you from agribazaar or through the Platform and/or the Services will create any warranty not expressly made in these Terms.</p>
                                    </div>
                                </p>
                                <p><strong>11.5</strong> To the extent permitted by law, under no circumstances agribazaar will be liable to you or to any third party for any indirect, special, incidental, punitive, or consequential loss or damages (including for any loss of profits, goodwill revenue, or data) or for the cost of obtaining substitute products or services or for any cost or liability arising out of or in connection with these Terms, the Platform and/or the Services, howsoever caused, whether such liability arises from any claim based upon contract, warranty, tort, including negligence, breach of statutory duty or otherwise, and whether or not agribazaar has been advised of the possibility of such loss or damages.</p>
                                <p><strong>11.6</strong> To the extent permitted by law, our total cumulative liability to you or any third party arising out of or in connection with these Terms, the Platform and/or the Services, from any and all causes of action claims (including contract, warranty, tort, negligence, breach of statutory duty or otherwise), will be limited to and will not exceed the fees you have actually paid to agribazaar during the 6 months preceding the claim giving rise to such liability, to a maximum of Rs. 1,00,000 (Rupees One Lakh only).</p>
                                <p><strong>11.7</strong> You understand and agree that we have set the level of our fees and entered into these Terms with you in reliance upon the limitations of liability set forth in these Terms, which allocate risk between agribazaar and the User and form the basis of a bargain between agribazaar and its Users.</p>
                                <p><strong>11.8</strong> Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent we may not, as a matter of applicable law, disclaim any warranty or limit our liabilities, the scope and duration of such warranty and the extent of our liability will be the minimum permitted under such law.</p>
                            </div>
                        </li>
                        <li id="contentA12">
                            PRIVACY
                            <div className='font-normal leading-loose'>
                                <p><strong>12.1</strong> Your privacy is very important to agribazaar. Our Privacy Policy set out at Part C herein below and at the following link: [Link to Part C], explains how we collect, use, protect, and when we share Personal Information (defined at Part C) and other data with others. By using the Platform and/or the Services, you consent to the collection, use, and transfer of your Personal Information (defined at Part C) in accordance with our Privacy Policy, which forms an integral part of these Terms.</p>
                            </div>
                        </li>
                        <li id="contentA13">
                            THIRD-PARTY FEES
                            <div className='font-normal leading-loose'>
                                <p><strong>13.1</strong> You may incur fees or charges for accessing or using data or services from third parties (such as your internet provider or mobile carrier) in connection with your use of the Platform, the Services or the content. You shall be responsible to pay for all such fees.</p>
                            </div>
                        </li>
                        <li id="contentA14">
                            GOVERNING LAW AND JURISDICTION
                            <div className='font-normal leading-loose'>
                                <p><strong>14.1</strong> If any dispute, controversy or claim arises out of or in connection with these Terms, including any question regarding its existence, validity or termination arising out of or in connection with this Terms (a "Dispute"), agribazaar and the User shall use all reasonable endeavours to resolve the matter amicably. If either party gives the other party notice that a Dispute has arisen and the two parties are unable to resolve the Dispute within fifteen (15) Working Days of service of the notice, then the Dispute shall be referred to the senior executive officers of agribazaar and the User, if the User is a registered or a corporate entity, (however, if the User is an individual, then the User himself), who shall attempt to resolve the Dispute. Neither agribazaar nor the User shall resort to arbitration against the other Party under this Agreement until fifteen (15) Working Days after such referral to a senior executive officer of agribazaar and/or the User, as the case may be.</p>
                                <p><strong>14.2</strong> All Disputes, which are unresolved pursuant to the preceding Clause 14.2 and which a party wishes to have resolved, shall be referred upon the application of either party to and finally settled in accordance with the rules of the Arbitration and Conciliation Act, 1996 and amendments thereto, unless otherwise specified in these Terms, (the "Rules") in force at the date on which these Terms were published, which Rules are deemed to be incorporated by reference to this clause. The number of arbitrators shall be three (3). One (1) arbitrator shall be appointed by the User and one (1) arbitrator shall be appointed by agribazaar, and together the two (2) arbitrators so appointed shall appoint the third (3rd) arbitrator. No officer, director, shareholder, employee, representative or relative or related party of any party may be nominated or appointed as an arbitrator.</p>
                                <p><strong>14.3</strong> The seat of the arbitration shall be Mumbai, unless otherwise specified in the Service Agreement or the Contract Note. The language of this arbitration shall be English and any document not in English submitted by any party shall be accompanied by an English translation. A written transcript of the proceedings shall be made and furnished to the parties. Notwithstanding anything to the contrary contained herein, in the event various Disputes arise in relation to the same or substantially similar set of facts, controversy or claim, the parties undertake that all such Disputes shall be dealt with under the same arbitral proceeding and separate arbitral proceedings shall not be initiated with respect to each such Dispute. To the extent that separate arbitral proceedings are initiated with respect to the same Dispute, all such proceedings shall be consolidated and dealt with by one arbitral tribunal.</p>
                                <p><strong>14.4</strong> The arbitrators shall have the power to grant any legal or equitable remedy or relief available under law, including injunctive relief (whether interim and/or final) and specific performance and any measures ordered by the arbitrators may be specifically enforced by any court of competent jurisdiction.</p>
                                <p><strong>14.5</strong> Any award of the arbitrator or arbitral tribunal, as the case may be, pursuant to this Clause shall be in writing and shall be final, conclusive and binding upon the parties, and the parties shall be entitled (but not obliged) to enter judgment thereon in any one or more of the highest courts having jurisdiction.</p>
                                <p><strong>14.6</strong> During any arbitration under this Clause except for the matters under Dispute, the parties shall continue to exercise their remaining respective rights and fulfil their remaining respective obligations under these Terms or any other agreement between the User and agribazaar.</p>
                                <p><strong>14.7</strong> Each party shall participate in good faith to reasonably expedite (to the extent practicable) the conduct of any arbitral proceedings commenced under these Terms.</p>
                                <p><strong>14.8</strong> The arbitrators shall decide on and apportion the costs and reasonable expenses (including reasonable fees of counsel retained by the parties) incurred in the arbitration. Subject to this sub-clause, the User (on the one hand) and agribazaar (on the other hand) shall share equally in the costs of the arbitrator’s or arbitral panel’s fees, as the case may be, but shall bear the costs of their own legal counsel engaged for the purposes of the arbitration.</p>
                                <p><strong>14.9</strong> No action, lawsuit or other proceeding (other than proceedings for the confirmation or enforcement of an arbitration award, an action to compel arbitration) shall be brought by or between the parties in connection with any matter arising out of or in connection with these Terms, provided that the parties shall have the right to approach the courts in Mumbai, unless otherwise specified in the Service Agreement or the Contract Note, to seek interim or injunctive relief during the pendency of any arbitration under these Terms.</p>
                                <p><strong>14.10</strong> Subject to the remaining provisions of these Terms, the parties agree that the courts in Mumbai , unless otherwise specified in the Service Agreement or the Contract Note, shall have jurisdiction with respect to any judicial proceedings ancillary to any arbitration hereunder, including but not limited to any proceeding to compel arbitration, to obtain interim relief in aid of arbitration, or to determine the validity and enforceability of any award, and each of the parties irrevocably submits to such non-exclusive jurisdiction. Notwithstanding the above, application may be made by any party to any court having jurisdiction wherever situated for enforcement of any award or judgment or the entry of whatever orders are necessary for such enforcement.</p>
                                <p><strong>14.11</strong> Notwithstanding anything to the contrary herein, nothing in this Clause shall prevent agribazaar from seeking interim or permanent injunctive relief or taking any other action in any court to enforce or protect its Intellectual Property rights, including but not limited to any action for money damages and/or equitable relief, including but not limited to injunctive relief.</p>
                            </div>
                        </li>
                        <li id="contentA15">
                            OTHER PROVISIONS
                            <div className='font-normal leading-loose'>
                                <p><strong>15.1</strong> These Terms (together with the agribazaar’s Privacy Policy, the Auction Terms, the Service Agreement (applicable to corporate Buyers and Sellers), the Contract Note [any other agreements to be added by agribazaar] and any other policies we publish) constitute the entire agreement and understanding between you and agribazaar, relating your access to and use of the Platform and/or Services, and they collectively supersede all prior understandings, communications or agreements, written or oral, between you and agribazaar on your use of the Platform and/or the Services.</p>
                                <p><strong>15.2</strong> You acknowledge that in accepting and agreeing to the Terms, you have not relied on, and shall have no remedy in respect of, any statements, assurance, representation, or warranty (whether of fact or of law and whether made innocently or negligently) made other than as set out in these Terms and/or any other agribazaar policy.</p>
                                <p><strong>15.3</strong> Under no circumstances shall agribazaar be held liable for any delay or failure in performance due, in whole or in part, to any acts of nature or other causes beyond our reasonable control.</p>
                                <p><strong>15.4</strong> The failure by agribazaar to enforce any right or provision of these Terms shall not prevent agribazaar from enforcing such right or provision in the future.</p>
                                <p><strong>15.5</strong> A waiver of any of these Terms will only be effective if it is in writing and signed by an authorized representative of agribazaar. No delay, omission or indulgence shall constitute a waiver of agribazaar’s rights under these Terms.</p>
                                <p><strong>15.6</strong> If any provision of these Terms is found to be unlawful, illegal, void, invalid or unenforceable (in whole or in part), then that provision will be deemed severable from these Terms and shall not affect the enforceability of any other provisions, and the remainder of these Terms shall continue in full force and effect.</p>
                                <p><strong>15.7</strong> You shall not assign, transfer or novate your rights or obligations under these Terms to third parties, save without the written consent of agribazaar, which consent may be withheld for any or no reason at our sole discretion.</p>
                                <p><strong>15.8</strong> A person who is not a party to any agreement made under these Terms shall have no right under these Terms.</p>
                            </div>
                        </li>
                        <li id="contentA16">
                            CONTACT/NOTICE
                            <div className='font-normal leading-loose'>
                                <p><strong>16.1</strong> Any notice or other communication to be given or served under, or in connection with, these Terms shall be in English and in writing, and it shall be transmitted by:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> personal hand delivery;</p>
                                        <p><strong className='text-sm'>(b)</strong> recognized express courier service;</p>
                                        <p><strong className='text-sm'>(c)</strong> the postal service;</p>
                                        <p><strong className='text-sm'>(d)</strong> return receipt or</p>
                                        <p><strong className='text-sm'>(e)</strong> electronic mail to agribazaar at the address/ contact details set out below. Notice given by personal service or express courier service shall be deemed effective on the date it is delivered to and received by agribazaar, and notice sent by postal service shall be deemed effective on the third day following its placement in the mail box addressed to agribazaar. Notice sent by electronic mail shall be deemed to be effective on the next Working Day.</p>
                                    </div>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <p className='font-medium'>To agribazaar</p>
                    <div>
                        <p> 601-604, A wing, Bonanza building, Sahar plaza, <br /> JB Nagar Metro Station, JB Nagar, <br /> Andheri East, Mumbai – 400059 <br /> info@agribazaar.com</p>
                    </div>
                </div>
            </div>
        )
    }
    const partBContent = () => {
        return (
            <div id="contentpartB" className='pt-0 px-2'>
                <h4 className='text-[#4165b3] text-xl font-semibold'>
                    PART B - TERMS AND CONDITIONS FOR AUCTION ON THE PLATFORM (THE “AUCTION TERMS”)
                </h4>
                <div className='pt-2 font-normal text-md leading-relaxed'>
                    <ul className='list-decimal font-medium leading text-left'>
                        <li id="contentB1">
                            INTRODUCTION
                            <div className='font-normal leading-loose'>
                                <p><strong>1.1</strong> Acting always in accordance with these Terms, the Users may buy and sell the Commodities through the Platform as Buyers and Sellers by means of flexible forward or reverse auctions. Each auction shall have a Buyer and a Seller with respect to a commodity being traded.</p>
                                <p><strong>1.2</strong> The procedures and processes for an auction on the Platform and the negotiation and formation of purchase and sale contracts in relation to such auctions via the Platform shall be in accordance with these Terms.</p>
                            </div>
                        </li>
                        <li id="contentB2">
                            LISTING AUCTIONS
                            <div className='font-normal leading-loose'>
                                <p><strong>2.1</strong>Users may list auctions to sell or buy any Commodity or Commodities, in the manner as set out herein below in this Clause 2.</p>
                                <p><strong>2.2</strong>
                                    The listing of auctions shall be made substantially in the form set out below subject to specific variations applicable to Commodities, and each such listing shall set out the following particulars:
                                    <div className='pl-4'>
                                        <p><strong>2.2.1</strong> Type of Auction: sell/buy;</p>
                                        <p><strong>2.2.2</strong> Product: name of the Commodity;</p>
                                        <p><strong>2.2.3</strong> Stock/Delivery Quantity: [specific quantity to be mentioned] in Metric Tonnes (MTs) or any other applicable unit;</p>
                                        <p><strong>2.2.4</strong> Stock/Delivery Location: [address], State;</p>
                                        <p><strong>2.2.5</strong> Trading Unit: MT or Quintal (Qtl.);</p>
                                        <p><strong>2.2.6</strong> Price Quote: Rs./Qtl or Rs./MT, which is exclusive of GST or other taxes;</p>
                                        <p><strong>2.2.7</strong> Tick Size: amount by which a User may vary its subsequent price bid;</p>
                                        <p><strong>2.2.8</strong> Reserve Price: minimum (sell) / maximum (buy) price below/above which the User cannot bid;</p>
                                        <p><strong>2.2.9</strong> Auction Date, Time and Duration;</p>
                                        <p><strong>2.2.10</strong> Order Margin/Ernest Money Deposit: initial amount to be paid by User before listing/participating in the auctions;</p>
                                        <p><strong>2.2.11</strong> Validity of Winning Bid: duration within which the winning bid must be accepted or rejected by the User who has listed the auctions;</p>
                                        <p><strong>2.2.12</strong> Quality Parameters: as per the requirement of the User listing the auction;</p>
                                        <p><strong>2.2.13</strong> Quantity variation: depending on the transaction;</p>
                                        <p><strong>2.2.14</strong> Gross/Net Weight Basis: whether the price bid is on gross weight or net weight basis;</p>
                                        <p><strong>2.2.15</strong> GST/Other Taxes: to be paid by the winner as applicable;</p>
                                        <p><strong>2.2.16</strong> Trade Value/Invoice Value: the calculation formula;</p>
                                        <p><strong>2.2.17</strong> Packaging Information: quantity along with packaging material details;</p>
                                        <p><strong>2.2.18</strong> Payment Gateway: whether online via the Platform or offline;</p>
                                        <p>
                                            <strong>2.2.19</strong> Payment Terms: includes the details such as:
                                            <div className='pl-8'>
                                                <ul className='list-disc'>
                                                    <li>balance amount to be paid;</li>
                                                    <li>period within which the balance payment (after the order margin) has to be made by the successful bidder;</li>
                                                    <li>penalty terms if balance amount not paid in specified timelines; and</li>
                                                    <li>whether partial lifting (Sell auctions) or delivery (Buy auctions) is allowed;</li>
                                                </ul>
                                            </div>
                                        </p>
                                        <p><strong>2.2.20</strong> Delivery Condition: delivery/lifting points along with the details of the costs and risks break-up between the Seller and the Buyer;</p>
                                        <p><strong>2.2.21</strong> Defaults: details of the penalty that will be levied on the Buyer or the Seller, in the event of the violation of the terms of the Contract Note;</p>
                                        <p><strong>2.2.22</strong> Force Majeure: Specific terms to be set out under the Contract Note; and</p>
                                        <p><strong>2.2.23</strong> Dispute Resolution: particulars of arbitration to be set out in the Contract Note.</p>
                                    </div>
                                </p>
                                <p><strong>2.3</strong> The auction listing shall consist of the information and the basic terms relating to an auction to sell or purchase the listed Commodity. Upon listing an auction on the Platform, the Users shall receive a notification via e mail, phone message and/or notification on the Platform itself, confirming that the auction has been posted and it will set out the information and the basic terms relating to an auction.</p>
                                <p><strong>2.4</strong> The User listing the auction may, in accordance with the procedure specified at Clause 4 of these Terms, retract or withdraw an auction at any time before the acceptance of such auction.</p>
                                <p><strong>2.5</strong> Unless retracted or withdrawn by the User who has listed the auction on the Platform, the auction shall be valid from the time at which it is listed until the bids are received and the Seller or the Buyer, as the case may be, has accepted or rejected the auction.</p>
                            </div>
                        </li>
                        <li id="contentB3">
                            BIDDING FOR THE AUCTIONS
                            <div className='font-normal leading-loose'>
                                <p><strong>3.1</strong> All the Users will be notified of the auctions listed on the Platform as soon as the same is listed by the Buyer or the Seller, as the case may be.</p>
                                <p><strong>3.2</strong> Interested Users may post a response to any auction(s) listed on the Platform by way of bids (the "Bidding" or "Bid"). A Bidder shall place the bids on the platform through its registered username and password, and as per the terms and conditions mentioned in the Contract Note(s) enclosed with the auction(s).</p>
                                <p><strong>3.3</strong> For the avoidance of doubt, the terms of an auction and/or the Contract Note (s) associated with any auction cannot be modified and/or altered by the Bidder. The Bidder must, therefore, acknowledge all the terms and conditions associated with the auction and deposit the Order Margin (as mentioned in the Contract Note) before placing the Bid.</p>
                                <p><strong>3.4</strong> The Bid(s) placed on the Platform, in relation to any listed auctions, using the registered username and password of a User, will be considered to be placed by the User (in their individual capacity or as authorized personnel) and will be binding on the User.</p>
                                <p><strong>3.5</strong> More than one Bid may be placed by a User on the Platform against any listed auction. Once the Bid is placed on the Platform against any listed auction, the User cannot withdraw the Bid.</p>
                                <p><strong>3.6</strong> The Bid(s) shall remain valid until the time period as mentioned in the Contract Note of the respective auction.</p>
                                <p><strong>3.7</strong> Bidders posting bids in response to an auction listing will not be able to view the Bids and/or details of other Bidders, but they will be able to ascertain that other Bids have been placed, and they may view the highest or the lowest bid, in relation to an auction.</p>
                                <p><strong>3.8</strong> The User listing the auction will be notified of the successful Bidder, and the details of the Bids received from such Bidder in the auction(s) listed by the User who listed the auction(s).</p>
                                <p><strong>3.9</strong> Only the Bid of the successful Bidder will remain open for the acceptance/rejection by the User who listed the auction. The bids of all other Users shall be automatically rejected and the Bidder(s) who made those Bids shall be automatically informed via e mail/SMS/any other form of phone message, of the fact that their Bids have been rejected and their Order Margin submitted towards participation in the auction will be released.</p>
                                <p><strong>3.10</strong> If the User listing the Auction is satisfied with the highest or the lowest Bid, as applicable, received in the auction, it may accept the Bid in accordance with Clause 5 of Part B of these Terms below. The successful Bidder will be notified of the Acceptance (defined below) via e mail and/or SMS.</p>
                                <p><strong>3.11</strong> If the User listing the auction is not satisfied with the highest or the lowest bid, as applicable, received in the auction, it can reject the Bid and list a fresh auction on the Platform. Upon rejection, the Bidder will be notified of the rejection via e mail and/or SMS.</p>
                                <p><strong>3.12</strong> When a Seller or a Buyer accepts a Bid, a binding contract comes into existence between the Buyer and the Seller, as set out in Clause 5 of this Part B of these Terms below.</p>
                                <p><strong>3.13</strong> The payments made by the User(s) towards listing the auctions and participating in the auctions on the Platform will not carry any interest.</p>
                            </div>
                        </li>
                        <li id="contentB4">
                            WITHDRAWAL OR RETRACTION OF AN AUCTION
                            <div className='font-normal leading-loose'>
                                <p><strong>4.1</strong> Provided that it has not been accepted, in accordance with Clause 5 of Part B of these Terms below, an auction may be retracted or withdrawn at any time by the User who has listed the auction on the Platform.</p>
                            </div>
                        </li>
                        <li id="contentB5">
                            OFFER AND ACCEPTANCE
                            <div className='font-normal leading-loose'>
                                <p><strong>5.1</strong>  By listing an auction on the Platform, the relevant User shall be making an offer to sell or purchase the Commodities in accordance with the Contract terms specified within such auction (the "Offer").</p>
                                <p><strong>5.2</strong>  When the Offer is accepted by another User ("Acceptance"), it becomes a valid and binding contract. For the avoidance of doubt, any Offer must be accepted in its entirety, in order to constitute a valid and binding Acceptance. Any modification or addition to any term or terms specified within an Offer cannot be made once the auction is Accepted.</p>
                                <p><strong>5.3</strong>  Upon an Acceptance, a binding contract shall exist between the relevant Users and a "Contract Confirmation" shall be issued by the Platform to each of the relevant Users, in the form of an e mail or a phone message, confirming that a contract has been entered into and setting out the terms of such contract.</p>
                                <p><strong>5.4</strong>  All Users accept that any Contract Confirmation shall constitute conclusive evidence of the terms of a valid and binding contract agreed and entered between the Buyer and the Seller through the Platform.</p>
                                <p><strong>5.5</strong>  For the avoidance of doubt, any question as to whether a valid and binding contract has been formed between the Users through the Platform shall be determined in accordance with the laws of the Republic of India.</p>
                                <p><strong>5.6</strong> agribazaar does not guarantee, nor is it responsible for ensuring a successful conclusion of any auction listing, Bids, Offers, Acceptance, or Contract Confirmation on the Platform.</p>
                                <p>
                                    <strong>5.7</strong> agribazaar does not guarantee that:
                                    <div className='pl-4'>
                                        <p><strong>5.7.1</strong> an Acceptance will result in performance or fulfillment of a contract or the parties’ obligations pursuant thereto; or</p>
                                        <p><strong>5.7.2</strong> that Commodities and/or underlying consideration amount will be exchanged between the Users that are parties to a contract as Buyers and Sellers.</p>
                                    </div>
                                </p>

                            </div>
                        </li>
                        <li id="contentB6">
                            THE LIMITED ROLE OF AGRIBAZAAR AND THE OBLIGATIONS BETWEEN THE BUYERS AND SELLERS
                            <div className='font-normal leading-loose'>
                                <p><strong>6.1</strong> The Platform acts only as a technology platform for e commerce, providing an online marketplace for facilitating sale and purchase of Commodities, between the Users through auctions. Use of the Platform and/or the Services is for the limited purpose of allowing Users to connect with one another, list auctions, place Bids, generate Offers, generate Acceptances, and generate Contract Confirmations, finalizing and recording the contracts, and terms thereof, reached between the relevant Users for the sale or purchase of Commodities on the Platform and/or via the use of the Services.</p>
                                <p><strong>6.2</strong> agribazaar and its Affiliates are not responsible for any negotiation, formalisation, execution and/or satisfaction of any contract reached among the Users in or outside the Platform and/or the portal providing Services. agribazaar is not responsible for holding any inventory of the Commodities, nor does agribazaar assume ownership or possession of the Commodities, wholly or in part, for any duration whatsoever, at any time during the transaction cycle starting from the listing of the auction to delivery of the Commodities to the Buyer’s location.</p>
                                <p><strong>6.3</strong> agribazaar does not provide payment gateway services to the Users. Further, agribazaar does not act as a collecting agent for the payments between the Users, in connection with any contract concluded via the Platform and/or using its Services. Further, agribazaar does not engage in the logistics, distribution and transportation or handling of any food items. agribazaar provides listing services for Commodities via the Platform which facilitates trading of Commodities between independent Buyers and Sellers. To the extent agribazaar needs information and assistance from its Users for complying with any applicable law, you (the User) undertakes to provide all such assistance and information to agribazaar for complying with applicable law.</p>
                                <p><strong>6.4</strong> Neither agribazaar nor the Platform nor any of the Services shall constitute, or shall be construed, or shall be deemed to be, a market, a broker, an agent, a trader, a legal representative, an intermediary, or an employee of any User nor vice versa. The Terms shall not be deemed to establish a joint venture, partnership, agency and/or any other association of whatsoever nature between agribazaar and any User.</p>
                                <p><strong>6.5</strong> agribazaar is not a party to, nor is it bound by, any contract for the sale and purchase of Commodities negotiated via the Platform and/or the Services.</p>
                                <p><strong>6.6</strong> You agree that any negotiations, dealings, transactions and/or contracts agreed between you and other Users of the Platform and/or of the Services are conducted entirely at your own risk.</p>
                                <p><strong>6.7</strong> Users should seek appropriate legal advice before entering any binding obligations for the purchase or sale of any Commodities.</p>
                                <p><strong>6.8</strong> Users should conduct their own investigations as to the accuracy of any information submitted by Users on the Platform and/or in connection with or via the Services.</p>
                                <p>
                                    <strong>6.9</strong> You agree that any purchases, sales, exchange of monies, exchange of Commodities and/or any other transactions are made solely in reliance on your own inquiries and inspections, and that agribazaar has not made and does not make any warranties or representations in relation to:
                                    <div className='pl-4'>
                                        <p><strong>6.9.1</strong> the legal capacity of the Users to enter into contractual agreements;</p>
                                        <p><strong>6.9.2</strong> the Users’ capacity to sell or buy the Commodities, including the legal entitlement or ownership of any seller to sell the Commodities;</p>
                                        <p><strong>6.9.3</strong> the Users’ financial ability to effectuate any payments;</p>
                                        <p><strong>6.9.4</strong> the content of the auctions , the Bids, the Offers or the Acceptance by the Users on the Platform;</p>
                                        <p><strong>6.9.5</strong> the existence, merchantability, suitability, quality and/or quantity of the Commodities for sale or purchase;</p>
                                        <p><strong>6.9.6</strong> the freedom of the Commodities for sale or purchase from any liens, pledges, charges or any other encumbrances; and/or</p>
                                        <p><strong>6.9.7</strong> the ability of Users to perform and/or complete any contract or transaction agreed or any obligation thereunder.</p>
                                    </div>
                                </p>
                                <p><strong>6.10</strong> You acknowledge that agribazaar shall not be liable for any direct, indirect, and/or consequential loss or damages (including legal fees and other expenses incurred), nor will agribazaar be liable for any loss of profit, loss of business, loss of opportunity or loss of reputation (whether or not such loss of profit, loss of business, loss of opportunity or loss of reputation is direct, indirect and/or consequential) arising from or in any way connected with your use of the Platform and/or the Services.</p>
                                <p><strong>6.11</strong> We remind all Users that the performance of contracts for the sale or purchase of Commodities on the Platform may be subject to certain specific legislations, rules and/or regulations imposed by the Users’ respective countries of incorporation or residency, by countries of import, export or transit, and/or otherwise. We strongly encourage you to obtain legal and professional advice on such applicable legislations, rules and/or regulations, before entering any binding contractual obligations through the Platform. Without limitation to the preceding part of this Clause 6.11, the performance of contracts for the sale or purchase of Commodities entered via the Platform and/or using Services may be subject to sanctions or penal consequences in certain countries. agribazaar shall not be liable for any breach of any sanction or penal provisions committed by any User arising out of or in connection with any contract concluded via the Platform and/or the use of Services or for any inability, failure or refusal to perform its contractual obligation by a User as a result of any sanction or penalties.</p>
                            </div>
                        </li>
                        <li id="contentB7">
                            THE TRANSFER OF TITLE AND RISK IN RELATION TO A TRADE THROUGH THE PLATFROM
                            <div className='font-normal leading-loose'>
                                <p><strong>7.1</strong> That with each or some of its corporate Users, agribazaar may enter into a commercial service agreement, in addition to these Terms (such agreement, "Service Agreement") That it is agreed and accepted by the corporate User (or the Company under the Service Agreement or the Buyers and Seller under a Contract Note) that the transfer of the beneficial title and ownership of Commodities pursuant to an auction shall pass from the Seller to the Buyer at the exact time, place and moment specified in the Contract Note for that auction. For avoidance of doubt, the risk of loss, damage or deficiency in relation to the Commodities shall pass from the Seller to the Buyer concurrently with the transfer of the beneficial title and ownership of Commodities at the exact time, place and moment specified in the Contract Note. For avoidance of doubt, the parties hereto and thereto acknowledge and agree that at no time during the transaction the title, ownership or the risk of loss of the Commodities shall pass or be deemed to have passed to agribazaar (which shall only be responsible for providing the Platform for the Buyer and the Seller to carry out a trade on the Platform independently of agribazaar).</p>
                                <p><strong>7.2</strong> agribazaar shall manage each auction as an intermediary and a third-party service provider providing and managing the Platform, including but not limited to providing services such as listing of trades, mediation in disputes and settlement of payments in relation to each auction. 7.3agribazaar is offering the Platform for the corporate User’s use only, and the corporate User shall be bound by its obligations under the Service Agreement and these Terms to deliver to the premises of the counterparty or lift from the premises of the counterparty, as may be applicable, the Commodities sold or bought through the Platform in accordance with the terms of the Contract Note for each auction.</p>
                            </div>
                        </li>
                        <li id="contentB8">
                            DISPUTE REGARDING PARTICULARS OF AN AUCTION
                            <div className='font-normal leading-loose'>
                                <p><strong>8.1</strong> Any dispute relating to the quantity, quality, price, mode of delivery, etc., of the Commodity, involving a corporate User, with whom agribazaar has a Service Agreement, shall be handled and resolved amicably by such User with its counterparty in the auction. Managing, avoiding or resolving such a dispute between Users shall not in any way be the responsibility of agribazaar, and such dispute shall also not entitle the corporate User (either as a Buyer or a Seller) to request for a re-auction and/or claim any losses, damages, costs, refunds or recoveries (including the refund of the service fees (as set out in the Service Agreement)) from agribazaar.</p>
                                <p><strong>8.2</strong> Without prejudice to agribazaar’s right to receive the service fee (as set out in the Service Agreement) for the auction and its right to disclaim any liability or responsibility arising from such a dispute, agribazaar may, at the request of both the Buyer and the Seller in an auction where quality of the Commodity has been impugned, agree to assist the Company in collecting, coding and sending a sample of the impugned Commodity, in the presence of the representatives of both the Buyer and the Seller, to a third-party lab for inspection.</p>
                            </div>
                        </li>
                        <li id="contentB9">
                            REPRESENTATIONS AND WARRANTIES GIVEN BY A CORPORATE USER RELATING TO ITS USE OF THE PLATFORM
                            <div className='font-normal leading-loose'>
                                <p><strong>9.1</strong> The corporate User represents and warrants to agribazaar that each of the Company Warranties (defined below) is, and will continue to be, true and accurate in all respects and not misleading as on the date of the Service Agreement between the User and agribazaar.</p>
                                <p><strong>9.2</strong> The corporate User shall not do, or omit to do, anything which would result in any of the Company Warranties (defined below) being breached or misleading at any time during the term of the Service Agreement.</p>
                                <p>
                                    <strong>9.3</strong> The corporate User represent and warrant to agribazaar that each of the Company Warranties (defined below)is, and will continue to be, true and accurate in all respects and not misleading as on the date of the Service Agreement and during the term of the Service Agreement. The "Company Warranties" are as follows:
                                    <div className='pl-4'>
                                        <p><strong>9.3.1</strong> The corporate User is a company, duly incorporated and validly existing under the laws of place of their incorporation.</p>
                                        <p><strong>9.3.2</strong> The corporate User has full power and authority to enter into and perform the Service Agreement and these Terms to which it is a party and all other documents executed by the corporate User which are to be delivered to agribazaar during the Term of the Service Agreement or its registration with the Platform, each of which constitutes (or when executed, will constitute) legal, valid and binding obligations of the corporate User in accordance with their respective terms.</p>
                                        <p><strong>9.3.3</strong> The execution, delivery and performance by the corporate User of the Service Agreement, these Terms or any documents in connection thereof will not constitute a breach of any laws or regulations in any relevant jurisdiction or result in a breach of or constitute a default under: (a) any law or regulation or any order, judgment or decree of any court or governmental authority by which the corporate User is bound; or (b) any agreement or instrument to which the corporate User is a party or by which they are bound.</p>
                                        <p><strong>9.3.4</strong> The corporate User is not and will not be required to give any notice to, or make any filing with, or obtain any permit, consent, waiver or other authorisation from any governmental authority or other persons in connection with the execution, delivery and performance of the Service Agreement, these Terms or any other document in connection thereof.</p>
                                        <p><strong>9.3.5</strong> The corporate User is not insolvent within the meaning of applicable law or unable to pay its debts under the insolvency laws of any applicable jurisdiction and has not stopped paying its debts as they fall due. No order has been made, petition presented, or resolution passed for the winding up of the corporate User. No administrator or any receiver or manager has been appointed by any person in respect of the corporate User or any of its assets and no steps have been taken to initiate any such appointment and no voluntary arrangement has been proposed. The corporate User has not become subject to any analogous proceedings, appointments or arrangements under the laws of any applicable jurisdiction.</p>
                                        <p><strong>9.3.6</strong> The corporate User is an experienced commercial party acting on its own account and has made its own independent decision to enter into the transactions contemplated by the Service Agreement based upon its own commercial judgment and upon advice from such advisers as it has deemed necessary.</p>
                                        <p>
                                            <strong>9.3.7</strong> As a Seller on the Platform, the corporate User represents and warrants as follows:
                                            <div className='pl-4'>
                                                <p>a) the Commodities listed and sold on the Platform have been validly procured by the corporate User in accordance with applicable Law;</p>
                                                <p>b) the Commodities listed and sold on the Platform are free from any encumbrances, claim or demand, and there is no agreement or commitment to give or create any encumbrance over or affecting such Commodities and no claim has been made by any person to be entitled to any such encumbrance;</p>
                                                <p>c) the corporate User has good right, full power and absolute authority to transfer the Commodities to the Buyer free from any encumbrances, claim or demand of any nature and the corporate User has not nor has anyone on their behalf done, committed or omitted any act, deed, matter or thing whereby the Commodities can be rendered unfit for sale and consumption in the market due to any environmental, legal or public health-related reasons; and</p>
                                                <p>d) that the User complies with all law applicable to the User and its business (including any food safety and standards rules and regulations).</p>
                                            </div>
                                        </p>
                                    </div>
                                </p>
                                <p><strong>9.4</strong> The corporate User shall not do or omit to do anything which would result in any of the Company Warranties being breached or misleading at any time during the term of the Service Agreement or during the term of the User’s registration with the Platform.</p>
                                <p><strong>9.5</strong> The corporate User shall notify agribazaar in writing with all material details of anything which is or may reasonably be expected to cause a breach of, or be inconsistent with, any of the Company Warranties immediately after it comes to its notice, at any time during the term of the Service Agreement or during the term of the User’s registration with the Platform.</p>
                                <p><strong>9.6</strong> The corporate User acknowledges that agribazaar is entering into the Service Agreement based on, and in reliance upon, representations in the terms of the Company Warranties under the Service Agreement.</p>
                                <p><strong>9.7</strong> Each of the Company Warranties shall be separate and independent and (unless expressly provided otherwise) shall not be limited by reference to any other Company Warranty or by anything in the Service Agreement.</p>
                                <p><strong>9.8</strong> agribazaar shall be entitled to claim that any of the Company Warranties given by the corporate User has been breached, is untrue or is misleading notwithstanding that agribazaar could have reasonably discovered the fact of such breach or inaccuracy on or before the date of the Service Agreement, or on or before the date of any Contract Note during the term of the Service Agreement.</p>
                                <p><strong>9.9</strong> If a breach of any Company Warranty occurs, or if the breach of any Company Warranty is discovered, during the term of the Service Agreement, agribazaar shall be entitled to treat the Service Agreement as terminated, provided that the accrued rights and liabilities of the parties shall continue to subsist.</p>
                                <p><strong>9.10</strong> To the extent that any of the Company Warranties are qualified by the knowledge of the corporate User, the knowledge of the corporate User shall be deemed to include any knowledge, belief or awareness which the corporate User would have had after having made all careful, usual and reasonable enquires.</p>
                            </div>
                        </li>
                        <li id="contentB10">
                            INDEMNIFICATION UNDER THE SERVICE AGREEMENT
                            <div className='font-normal leading-loose'>
                                <p><strong>10.1</strong> The corporate User hereby accepts and agrees to fulfil and comply with the obligations under the Service Agreement, including fulfilment of such other necessary obligations as provided in these Terms. Further, the corporate User hereby agrees to hold harmless the indemnified persons under the Service Agreement from any suits or proceedings filed before any court of law, including any tribunal, in respect of the losses caused to the indemnified persons under the Service Agreement on account of the breach of the Corporate User’s obligations under the Service Agreement and these Terms by the corporate User.</p>
                                <p><strong>10.2</strong> Any compensation or indemnity as referred to above, shall be such as to place the indemnified persons under the Service Agreement in the same position as it would have been had there not been any breach of the Service Agreement and as if the Company Warranties or covenant or undertaking under which the indemnified persons under the Service Agreement are to be indemnified had been accurate or performed properly or fully.</p>
                                <p><strong>10.3</strong> The rights and remedies of the indemnified persons under the Service Agreement in respect of any breach of the Service Agreement, including without limitation breach of any of the Company Warranties, shall not be affected by any act or happening which otherwise might have affected such rights and remedies, except by a specific written waiver by the indemnified persons under the Service Agreement.</p>
                                <p><strong>10.4</strong> The rights of indemnification of the indemnified persons under the Service Agreement shall be in addition to all other rights available to them in law, equity or otherwise, including without limitation rights of specific performance, recession and restitution.</p>
                                <p><strong>10.5</strong> The corporate User shall not pursue any claim, seek damages, reimbursements or contribution from the indemnified persons in respect of any claim.</p>
                            </div>
                        </li>
                        <li id="contentB11">
                            LIMITATIONS OF LIABILITY, DISCLAIMER OF WARRANTIES AND GENERAL RELEASE OF AGRIBAZAAR UNDER THE SERVICE AGREEMENTS
                            <div className='font-normal leading-loose'>
                                <p><strong>11.1</strong> As a User of the Platform and the Services, the corporate User shall use the Platform and the Services at its own risk. To the fullest extent permissible by law, agribazaar and its Affiliates disclaim:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> any representations or warranties regarding the Service Agreement, these Terms, the Contract Note or any other transactions contemplated by or in relation thereto, including any implied warranties of merchantability, fitness for a particular purpose, or non-infringement;</p>
                                        <p><strong className='text-sm'>(b)</strong> implied warranties arising out of the course of dealing, course of performance, or usage or trade; and</p>
                                        <p><strong className='text-sm'>(c)</strong> any obligation, liability, right, claim, or remedy in tort, whether or not arising from our negligence. agribazaar does not represent or warrant that the functions contained in the Platform and/or the Services will meet the corporate User’s requirements or be available, timely, secure, uninterrupted, or error free, and agribazaar will not be liable for any service interruptions, including but not limited to system failures or other interruptions that may affect the receipt, processing, acceptance, completion, or settlement of any transactions.</p>
                                    </div>
                                </p>
                                <p><strong>11.2</strong> It is accepted and agreed by the corporate User and agribazaar that agribazaar’s responsibility is to match Buyers and Sellers through the Platform. In the event no interested Buyers or Sellers, as the case may be, participate in an auction, the corporate User shall not hold agribazaar liable for any shortfall in the trade or service levels. Further, agribazaar shall not be responsible for any direct or indirect losses to the corporate User in relation to the transactions contemplated under the Service Agreement.</p>
                                <p><strong>11.3</strong> Because agribazaar is not involved in transactions between the Buyers and Seller or other participant dealings, if a dispute arises between one or more participants (including the corporate User as a Buyer or a Seller), the corporate User releases agribazaar (and its agents and employees) from any claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</p>
                                <p><strong>11.4</strong> The corporate User acknowledges that, and agrees that it shall not hold agribazaar responsible or liable for, any downtime or other service interruptions or technical issues associated with the provision of online access to the modules and dashboards contained in the Platform. The corporate User shall look solely to the operator of agribazaar’s chosen online hosting platform and the other service providers that may facilitate online access for all technical support.</p>
                                <p><strong>11.5</strong> The corporate User shall not hold agribazaar responsible or liable for any shortage or non-fulfilment of the Services on the Platform or any other related website or mobile application, which shortage or non-fulfilment may arise due to or out of a technical failure or malfunctioning or otherwise, and the corporate User undertakes that, in such situations, the corporate User shall not claim any right, damages, relief, cost, recoveries, charges, penalties, etc., from agribazaar, including not claiming on any grounds under the consumer protection laws in India or any other applicable laws.</p>
                                <p><strong>11.6</strong> The corporate User shall not hold agribazaar liable for any and all fees, costs, charges, expenses, etc., incurred and payable by the corporate User to any third party service provider for any services needed for accessing the Platform, which services may include airtime, Internet services, connection costs, etc.; for these services and similar services, the expenses are to be borne by the corporate User itself.</p>
                                <p><strong>11.7</strong> Further, agribazaar shall not be responsible or liable for any malfunctioning or breakdown of the corporate User’s systems (e.g., computer or mobile hardware or Software) or any other device or application used for accessing or using the Platform, and the corporate User shall not claim any damage, loss, either direct or indirect, arising out of the use of the Platform by the corporate User.</p>
                                <p><strong>11.8</strong> agribazaar shall not be liable to the corporate User or any third party for any consequential, incidental, special, or indirect damages, regardless of whether the corporate User or third party has been advised of the possibility of such damages.</p>
                                <p><strong>11.9</strong> agribazaar shall not be liable (whether in contract, warranty, tort (including negligence, product liability, or other theory), or otherwise) to the corporate User (as a Buyer, Seller or a User) or any other person for cost of cover, recovery, or recoupment of any investment made by the corporate User or its affiliates in connection with this agreement, or for any loss of profit, revenue, business, or data or punitive or consequential damages arising out of or relating to the Service Agreement, even if agribazaar has been advised of the possibility of those costs or damages.</p>
                                <p><strong>11.10</strong> Further, under no circumstances, agribazaar’s aggregate liability arising out of or in connection with the Service Agreement or the transactions contemplated therein shall exceed at any time the total amounts during the prior one (1) month period paid by the corporate User to agribazaar in connection with the particular Service giving rise to the claim.</p>
                                <p><strong>11.11</strong> The foregoing limitations shall apply notwithstanding the failure of essential purpose of any limited remedy.</p>
                                <p><strong>11.12</strong> The single or partial exercise of any right or remedy under the Service Agreement shall not preclude any other right or remedy nor restrict any further exercise of any such right or remedy.</p>
                                <p><strong>11.13</strong> The rights and remedies provided in the Service Agreement are cumulative and do not exclude any rights or remedies provided by law.</p>
                                <p><strong>11.14</strong> The rights and remedies of agribazaar under the Service Agreement shall not be affected by the expiry of any limitation period prescribed by law in relation to a claim under the Service Agreement (including in relation to agribazaar’s claims arising from the breach of representations and warranties by the corporate User under the Service Agreement).</p>
                            </div>
                        </li>
                        <li id="contentB12">
                            USE OF INFORMATION, INTELLECTUAL PROPERTY AND CONFIDENTIALITY
                            <div className='font-normal leading-loose'>
                                <p><strong>12.1</strong> The corporate User shall not, and will cause its Affiliates not to, directly or indirectly:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> disclose any auction information (except that the corporate User may disclose that information solely as necessary for it to perform its obligations under the Service Agreement if it ensures that every recipient uses the information only for that purpose and complies with the restrictions applicable to the corporate User related to that information);</p>
                                        <p><strong className='text-sm'>(b)</strong> use any auction information for any marketing or promotional purposes whatsoever, or otherwise in any way inconsistent with agribazaar’s privacy policy or applicable law;</p>
                                        <p><strong className='text-sm'>(c)</strong> contact a person that has ordered the Commodities offered by the corporate User with the intent to collect any amounts in connection therewith or to influence that person to make an alternative transaction;</p>
                                        <p><strong className='text-sm'>(d)</strong> disparage agribazaar, its Affiliates, or any of agribazaar’s or other User’s products or services; or</p>
                                        <p><strong className='text-sm'>(e)</strong> target communications of any kind on the basis of the intended recipient being a User.</p>
                                    </div>
                                </p>
                                <p><strong>12.2</strong> During the course of the corporate User’s use of the Platform and/or the Services, the corporate User may receive information relating to agribazaar, the Platform or to the Services, including but not limited to some auction or listing information, that is not known to the general public ("Confidential Information"). The corporate User agrees that:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> all Confidential Information shall remain agribazaar’s exclusive property;</p>
                                        <p><strong className='text-sm'>(b)</strong> the corporate User shall use Confidential Information only as is reasonably necessary for its participation in the Services;</p>
                                        <p><strong className='text-sm'>(c)</strong> the corporate User shall not otherwise disclose Confidential Information to any other person or for any other purpose; and</p>
                                        <p><strong className='text-sm'>(d)</strong> the corporate User shall take all measures to protect the Confidential Information against any use or disclosure that is not expressly permitted in the Service Agreement. The corporate User shall not issue any press release or make any public statement related to the Services, or use agribazaar’s name, trademarks, or logo, in any way (including in promotional material) without agribazaar’s advance written permission, or misrepresent or embellish the relationship between agribazaar and the corporate User in any way. The corporate User shall not disclose Confidential Information to any third party even after the termination or expiry of the term of the Service Agreement.</p>
                                    </div>
                                </p>
                                <p><strong>12.3</strong> Under no circumstances shall the corporate Users disclose to any third party any content, image, instructions, diagram, text, or other information from the Platform or that is based upon or aggregated or derived from the Platform and that effectively discloses information contained in the Platform or could allow a third party to ascertain information contained in the Platform, without the prior written consent of agribazaar.</p>
                                <p><strong>12.4</strong> Under no circumstances shall the corporate User copy, use, re-use, produce, reproduce or duplicate, by any means, the content on the Platform or any materials or information associated with the Platform (regardless of whether those materials and contents were prepared by agribazaar, the corporate User or a third party), without the prior written consent of agribazaar.</p>
                                <p><strong>12.5</strong> Further, under no circumstances shall the corporate User sell, lease, assign, sublicense, or otherwise transfer or disclose, or permit the transfer or disclosure of access to the Platform, in whole or in part, to any third party, or permit any third party to use or access the Platform or materials and contents associated with the Platform, without the prior written consent of agribazaar.</p>
                                <p><strong>12.6</strong> The Company acknowledges and agrees that agribazaar shall exclusively retain all right, title, and interest in and to the Platform, including without limitation to all the Intellectual Property and any other rights associated with agribazaar and the Platform, and that at no time, whether during the term of this Agreement or thereafter, shall the Company have any right to use the Intellectual Property or any other rights of agribazaar or the Platform without the prior written consent of agribazaar. The Company acknowledges and agrees that it will not contest or assist any third party in contesting agribazaar’s ownership rights to the Platform or any Intellectual Property or any other rights of agribazaar or any of Agribaaar’s employees, members, officers, directors, affiliated entities, licensees, agents, or other representatives.</p>
                                <p><strong>12.7</strong> agribazaar shall have the right to use the name, image, likeness or other information about the Company in agribazaar’s advertising and/or promotion.</p>
                            </div>
                        </li>
                        <li id="contentB13">
                            RELATIONSHIP BETWEEN PARTIES
                            <div className='font-normal leading-loose'>
                                <p>The corporate User shall have no authority to control the day-to-day activities of agribazaar. Further, the corporate User shall have no authority to make or accept any offers or representations or create or assume any liabilities on agribazaar’s behalf. The Service Agreement shall not create an exclusive relationship between the corporate User and agribazaar. Nothing expressed or mentioned in or implied from the Service Agreement is intended or will be construed to give to any person other than the parties to the Service Agreement any legal or equitable right, remedy, or claim under or in respect to the Service Agreement. The Service Agreement and all the representations, warranties, covenants, conditions, and provisions in the Service Agreement are intended to be and are for the sole and exclusive benefit of agribazaar and the corporate User. As between the corporate User and agribazaar, the corporate User shall be solely responsible for all obligations associated with the use of any third-party service or feature that the corporate User permits agribazaar to use on its behalf, including compliance with any applicable terms of use. The corporate User will not make any statement, whether on the Platform or otherwise, that would contradict anything in this Clause.</p>
                            </div>
                        </li>
                        <li id="contentB14">
                            SERVICE FEES
                            <div className='font-normal leading-loose'>
                                <p>
                                    agribazaar will charge a service fee for providing the Platform and/or the Services for facilitating an auction. The percentage of the Service Fees will be mentioned in the Contract Note for every auction. Payment of such Service Fees shall be made against and in accordance with the terms of agribazaar’s invoice issued to the User, including in accordance with the time period specified for payment and using the bank account details contained in the Contract Note.<br />
                                    In the event of any delayed payment or non-payment, agribazaar shall be entitled to take any steps it considers appropriate, including without limiting to any other remedy available to us, for:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> charging interest at a rate of 18% per annum on the amount due in accordance with our invoice; and/or</p>
                                        <p><strong className='text-sm'>(b)</strong> cancelling the User’s access to the Platform and/or the Services.</p>
                                    </div>
                                    agribazaar reserves the right to modify the Service Fees from auction to auction at its sole discretion and/or depending on each Service.<br />
                                    Payment of the Service Fees shall be made against and in accordance with the terms of the specific Contract Note and the agribazaar invoice issued to the User, including the time period specified for payment and bank account details contained therein.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
    const partCContent = () => {
        return (
            <div id="contentpartC" className='pt-0 px-2'>
                <h4 className='text-[#4165b3] text-xl font-semibold'>
                    PART C - PRIVACY POLICY
                </h4>
                <div className='pt-2 font-normal text-md leading-relaxed flex flex-col gap-4'>
                    <div>This document explains how agribazaar collects, processes, stores and/or shares any Personal Information (as defined below) and/or other information from or about the Users.</div>
                    <div>WE VALUE OUR USERS’ PRIVACY AND SO WE HAVE PREPARED THIS PRIVACY POLICY DOCUMENT TO DEMONSTRATE THIS.</div>
                    <div>By accessing and/or using the Platform, you consent to the collection, storage, disclosure and other uses of your information in accordance with this Privacy Policy. As set out in the General Terms set out herein above, you must be at least of legal age to access the Platform and/or use the Services.</div>
                    <div>PLEASE CAREFULLY READ THIS DOCUMENT BEFORE SUBMITTING ANY INFORMATION ON THE PLATFORM.</div>
                    <div>We may update this statement from time to time by publishing a revised version on the Platform in accordance with the Privacy Policy. Please check this Privacy Policy document each time before you share Personal Information with us.</div>
                    <div>agribazaar may share the information (including your Personal Information) with our Affiliates, employees, directors, agents, contractors, business partners and our successors or permitted assigns or any third party service provider, for the purposes of providing you access to the Platform and/or Services, and by registering on the Platform and/or for our Services, you provide your express consent to us that we may collect, store, share, transmit or otherwise use your information (including Personal Information) in accordance with this Privacy Policy in particular and these Terms in general.</div>
                </div>
                <div className='pt-2 font-normal text-md leading-relaxed'>
                    <ul className='list-decimal font-medium leading text-left'>
                        <li id="contentC1">
                            COLLECTION
                            <div className='font-normal leading-loose'>
                                <p><strong>1.1</strong> Personal Information: When you create an account or register with us, we collect information that can be used to identify you ("Personal Information"). Personal Information may include your e mail address, name, username, address, and phone number, Aadhar number, PAN Card number and any other personally identifiable information that you may choose to provide us or that you choose to include in your account on the Platform. We may also collect Personal Information that you provide when you list an auction, place Bids, make an Offer or an Acceptance, or otherwise communicate with us or other Users on or through the Platform.</p>
                                <div><p>THE PLATFORM WILL NOT BE AVAILABLE TO INDIVIDUALS OR ENTITIES NOT REGISTERED WITH AGRIBAZAAR. IF YOU ARE NOT REGISTERED, YOU WILL NOT BE ABLE TO USE THE PLATFORM.</p></div>
                                <p><strong>1.2</strong> Your Content and Communications: Our Services permit you to interact with us through the notification system. We collect and store all of the comments, communications and chats you post, transmit or generate on the Platform and/or through the Services. When you ask for assistance from agribazaar for customer support, the contact information you provide will be collected, as well as the information which you post while using the Platform and/or the Services, such as, your user ID number, etc., and any correspondence or information contained within.</p>
                                <p><strong>1.3</strong> Cookies: agribazaar may employ cookies to track your access to the Platform and/or Services. These technologies operate either by placing a small file which stores some information on your computer or mobile device; and/or by accessing information on your device. agribazaar uses cookies and similar technologies to recognize your device, for example by identifying your IP address, and to collect data such as your device’s model, operating system and screen size, other applications installed on your device, time and date, and other information about how you use the Platform and/or Services. agribazaar uses this information
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> to store and access information;</p>
                                        <p><strong className='text-sm'>(b)</strong> to enable agribazaar to provide you with more customized services; and</p>
                                        <p><strong className='text-sm'>(c)</strong> to collect other information about your use of our Services.</p>
                                    </div>
                                </p>
                            </div>
                        </li>
                        <li id="contentC2">
                            AGRIBAZAAR’S USE AND DISCLOSURE OF INFORMATION
                            <div className='font-normal leading-loose'>
                                <p><strong>2.1</strong> Use of information</p>
                                <p>
                                    You agree that agribazaar and/or service providers on agribazaar’s behalf may use the information they collect, including your Personal Information for the purposes of:
                                    <div className='pl-8'>
                                        <ul className='list-disc'>
                                            <li>contacting you for customer support;</li>
                                            <li>sending you updates or information about the Platform and/or Services;</li>
                                            <li>managing your account and relationship with the Platform and/or Services, and improving your experience when you use it;</li>
                                            <li>improving the Services, research, surveying and engaging with you, for example by sending you communications for these purposes;</li>
                                            <li>marketing and promotion of the Platform and/or Services and/or other products;</li>
                                            <li>personalizing and optimizing the Platform and/or Services;</li>
                                            <li>sharing promotional content and/or advertising; and</li>
                                            <li>sharing promotional content and/or advertising; and</li>
                                        </ul>
                                    </div>
                                    You may unsubscribe from some of these messages by sending an e mail to us at the e mail address set out at Clause 9 of this Part C below.
                                </p>
                                <p>
                                    <strong>2.2</strong> Information available
                                    <p>By using the Platform and/or the Services you make certain that your Personal Information will be available to other Users. For example, when a trade is closed on the Platform, agribazaar provides the contact information of the execution profile or, in case such profile is not available, the commercial profile, to other Users. When you list an auction with your details on the Platform, such listing, post and/or activity are shared with other Users who may then share this information with others through the Platform and/or Services or outside the Platform and/or Services. You acknowledge that you have no expectation of privacy in relation to the auctions, Bids, Offers, Acceptance, Contract Confirmation, which are posted on the Platform.</p>
                                    <div>NOTWITHSTANDING THE ABOVE, LISTINGS/POSTINGS MADE ON THE PLATFORM WILL ONLY BE SHARED WITH OTHER USERS.</div>
                                </p>
                                <p>
                                    <strong>2.3</strong> Location
                                    <p>When you use our services we may collect information about your location. With your permission, we may also collect information about your precise location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors such as compasses. We use all this information to personalize the app and make it easier for you to get the latest updates. In case you don't want us to use your location, you can always turn off your GPS.</p>
                                </p>
                                <p>
                                    <strong>2.4</strong> Other transfers
                                    <p>To provide access to its Platform and/or Services, agribazaar may disclose information to third parties which provide agribazaar administrative or other business services, such as hosting.</p>
                                    <div>ANY DISCLOSURE IS ALWAYS ON A CONFIDENTIAL BASIS.</div>
                                    <p>If agribazaar’s control is acquired by person(s) other than the present promoters, we may share Personal Information with such person(s), but even they will have to store and use such information in compliance with this Privacy Policy. We may also disclose information for other purposes to the extent authorized and/or required by law or judicial order. Otherwise, we do not disclose information for any other purposes, unless it has your consent.</p>
                                </p>
                            </div>
                        </li>
                        <li id="contentC3">
                            SECURITY OF YOUR PERSONAL INFORMATION
                            <div className='font-normal leading-loose'>
                                <p>agribazaar takes all reasonable and sound steps to ensure that the information is protected against misuse, loss, unauthorized access, modification, and/or disclosure. agribazaar adopts and applies appropriate data collection, storage, management practices, and security procedures to protect against unauthorized access, alteration, addition, deletion, disclosure, and/or destruction of a User’s personal information, including their username, e mail address, password, transaction information, and any other data stored on the Platform. However, no data storage or transmission over the Internet or other network can be guaranteed to be 100% secure. Accordingly, agribazaar does not guarantee that information will not be accessed, disclosed, altered or destroyed by any breach of any of the abovementioned safeguards. To the extent applicable, agribazaar complies with all applicable data protection laws and you (the User) are duly bound to provide all reasonable assistance and information to agribazaar in relation to compliance with such laws.</p>
                            </div>
                        </li>
                        <li id="contentC4">
                            ACCESS TO PERSONAL INFORMATION
                            <div className='font-normal leading-loose'>
                                <p>You have the right to access the Personal Information we collect and hold about you. If at any time you would like to access or change your Personal Information, or you would like more information on our approach to protecting your privacy, please contact us at the contact details set out at Clause 9 of this Part C. You can opt out of receiving certain promotional or marketing material by selecting the unsubscribe option contained within the e mails providing such material or contacting us at the contact details set out at Clause 9 of this Part C. However, you cannot opt out of receiving all e-mails from us in relation to youe use of the Platform, such as, e-mails about the status of your account.</p>
                                <p>
                                    <strong>4.1</strong> Account Update/Delete options
                                    <p>While registering on our platform, we undertake to respect the confidentiality of your personal data and to guarantee you can exercise your rights. The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your personal data directly by contacting us. You can reach us at customersupport@agribazaar.com or call us on +91-9090397777.</p>
                                </p>
                            </div>
                        </li>
                        <li id="contentC5">
                            TERM
                            <div className='font-normal leading-loose'>
                                <p>agribazaar may retain the information (including Personal Information) for as long as is necessary to fulfill the purposes for which it was collected or as needed to provide, even after you have discontinued or deleted any account or have ended the provision of the Services, if retention of such information is reasonably necessary to comply with agribazaar’s legal obligations, regulatory requirements and for resolving disputes between Users or for preventing fraud, for backup, archival, and/or audit purposes or any other use.</p>
                            </div>
                        </li>
                        <li id="contentC6">
                            LINKS
                            <div className='font-normal leading-loose'>
                                <p>On the Platform, you may find links to third party websites. You understand that when you click on these links any data which you provide afterwards is subject to that third party's privacy policy and not to agribazaar’s. Consequently, agribazaar takes no responsibility for the content, safety or security of any third-party websites.</p>
                            </div>
                        </li>
                        <li id="contentC7">
                            GENERAL TERMS
                            <div className='font-normal leading-loose'>
                                <p>Notwithstanding anything to the contrary in this Privacy Policy, agribazaar may preserve or disclose your information:
                                    <div className='pl-4'>
                                        <p><strong className='text-sm'>(a)</strong> to the extent reasonably necessary to comply with a law, regulation or legal request;</p>
                                        <p><strong className='text-sm'>(b)</strong> to protect the safety of any person;</p>
                                        <p><strong className='text-sm'>(c)</strong> to address fraud, security or technical issues; or</p>
                                        <p><strong className='text-sm'>(d)</strong> to protect agribazaar’s rights or property. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s request to disclose your information. If any court or other competent authority finds any of this Privacy Policy to be invalid or unenforceable, the other terms of this Privacy Policy will not be affected.</p>
                                    </div>
                                </p>
                            </div>
                        </li>
                        <li id="contentC8">
                            CHANGES TO THIS PRIVACY POLICY
                            <div className='font-normal leading-loose'>
                                <p>The most recent version of this Privacy Policy will govern the use of the information (including Personal Information) on the Platform. We may revise or amend this Privacy Policy from time to time. If we decide to change this Privacy Policy, we will inform you by posting the revised Privacy Policy on the Platform. agribazaar may also, but is not required to, notify you of changes to the Privacy Policy via e mail to the email address associated with your account. If you object to any changes to the Privacy Policy, you should immediately stop using the Platform and/or Services and close any related accounts. By continuing to access or use the Platform and/or Services after changes have become effective, you agree to be bound by the revised Privacy Policy.</p>
                            </div>
                        </li>
                        <li id="contentC9">
                            CONTACT
                            <div className='font-normal leading-loose'>
                                If you have any questions or comments about this Privacy Policy, please contact:
                            </div>
                        </li>
                    </ul>
                    <p className='font-medium'>To agribazaar</p>
                    <div>
                        <p> 601-604, A wing, Bonanza building, Sahar plaza, <br /> JB Nagar Metro Station, JB Nagar, <br /> Andheri East, Mumbai – 400059 <br /> info@agribazaar.com</p>
                    </div>
                </div>
            </div>
        )
    }
    const partDContent = () => {
        return (
            <div id="contentpartD" className='pt-0 px-2'>
                <h4 className='text-[#4165b3] text-xl font-semibold'>
                    PART D – SAMPLE CONTRACT NOTE
                </h4>
                <div className='pt-2 font-normal text-md leading-relaxed'>
                    <ul className='list-decimal font-medium leading text-left'>
                        <li id="contentD1">
                            AUCTION CONTRACT NOTE – SELL SIDE
                            <div className='font-normal leading-loose'>
                                <p>This contract note sets out the specific terms in respect of a particular Auction conducted on the Platform and constitutes a binding agreement between the Buyer, the Seller and agribazaar (such parties, "Parties") (such agreement, "Contract Note"). This Contract Note needs to be read with and understood in light of the T&Cs and the Service Agreement. However, in the event of any inconsistency or contradiction between the specific terms of this Contract Note and the terms set out under the T&Cs and/or the Service Agreement, the terms of this Contract Note shall prevail and will be binding on the Parties hereto.</p>
                            </div>

                            <h2>Contract Specification</h2>
                            <div>
                                <table className="w-full table-fixed">
                                    <tbody className='border-separate '>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Commodity</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Chana – Australia (Imported - Vessel Name - xxxxx)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Stock Quantity</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>500 MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Stock Location</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Mundra- Gujarat</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Trading Unit</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>MT as specified in the auction listing.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Price Quote</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs per Quintal (exclusive of Goods and Services Tax ("GST") or any other taxes).</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Quantity Variation</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>± 5% of listed quantity.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Minimum Lot Size</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>50 MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Incremental Lot Size</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>25 MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Auction Type</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>2 Round (Yankee- Sell)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Tick Size</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. 1</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Reserve Price</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. 4200 per Quintal</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Auction Date Time</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Month dd, 2019 (referred to herein after as "T") starting at 3:00 pm, as stated in the auction listing on the Platform. There will be no extensions in the auction duration.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Seller EMD</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>As set out in the Corporate Agreement</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Buyer EMD</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. 2,000 per MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Bidding Rules</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>Buyers can quote for any quantity up to maximum auctioned quantity.</li>
                                                    <li>Buyers can revise their quantity subject to given lot size.</li>
                                                    <li>Buyers can revise their bids upwards and only latest bid will be considered.</li>
                                                    <li>The auction runs on Price Quantity Time priority (PQT)</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Winning Bid/ Auction Confirmation</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>All the bidders with their bid price more than the reserve price in round 1 will be auto approved till the availability of quantity.</li>
                                                    <li>After round 1, seller will be given 15 minutes time to revise his reserve price downwards.</li>
                                                    <li>Round 2 is price matching round, where bidders will be asked to match with the reserve price with the quantity allotted by the system.</li>
                                                    <li>Post auction, confirmation e-mail will be sent to both parties with buyer and seller details.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Gross/Net wt. basis</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Price Quote is on Gross weight basis</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>GST/ Other Taxes</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div>i) GST/ Other Taxes</div>
                                                <div>ii) Mandi Cess will be paid by seller, if applicable. The receipt of paid Mandi Cess will be required by the Buyer along with the invoice.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Packaging</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>50/60 Kg. Jute/PP Bags</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Trade Value</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Commodity Value [Stock Quantity * Winning Bid] + GST/Other taxes (if applicable)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>agribazaar service fee</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div>Buyer-0.5% of Commodity Value + GST (18%)</div>
                                                <div>Seller-0.5% of Commodity Value + GST (18%)</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Payment Gateway</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                All payments shall be done through the agribazaar Unique Account Number
                                                <div className='text-md font-medium'>
                                                    <p><strong>Payee Name</strong> – Star Agribazaar Technology Private Limited</p>
                                                    <p><strong>Account No (Unique to each User)</strong> – ZAGBZRXXXXXXXXXXXX</p>
                                                    <p><strong>Bank</strong> – IndusInd Bank</p>
                                                    <p><strong>IFSC</strong> – INDB0000018</p>
                                                    <p><strong>Bank Branch</strong> – Andheri, Mumbai</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Quality Parameters and variation</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>The quality of Chana for sale is on "as is where is basis". It is advisable to the interested buyers to visit the warehouses for inspection of stocks with prior intimation to agribazaar before participating in e-auction. The buyer shall be solely responsible for inspecting the stocks before participating in e-auction.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Timeline for payment & delivery</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div>
                                                    <table className='border-2 border-collapse'>
                                                        <thead >
                                                            <tr className='border-2 border-collapse'>
                                                                <th className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Min. offer Quantity (MT) for Buyer</th>
                                                                <th className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Max. Lifting Period (Calendar Days) for Buyer</th>
                                                                <th className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Payment timeline (Calendar days) for Buyer</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>15% (of bid quantity) or 50 MT whichever is higher</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>T + 3 days</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>T + 1 days</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Balance Quantity</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>T + 7 days</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>T + 2 days</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className=' pl-6'>
                                                    <ul className='list-disc'>
                                                        <li>'T' is date of auction.</li>
                                                        <li>'D' is date of successful delivery. D is less than or equal to the maximum delivery period.</li>
                                                        <li>If last day for delivery will fall on Sunday, then coming Monday will be considered as last delivery day.</li>
                                                        <li>If last day for payment will fall on bank holiday, then next bank working day will be considered as last due date of payment.</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Payment terms</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div className=' pl-6'>
                                                    <ul className='list-disc'>
                                                        <li>
                                                            Buyer will have to deposit payment to agribazaar settlement accountas per above mentioned timelines. Thepayment will include the following:
                                                            <div className='pl-4'>
                                                                <p>1. Stock Quantity* Winning Bid</p>
                                                                <p>2. GST and any other tax to be deposited by the Buyer, if applicable</p>
                                                                <p>3. agribazaar service fee</p>
                                                                <p>4. Charges for any other services availed.</p>
                                                            </div>
                                                        </li>
                                                        <li>Seller will have to generate and provide invoice for the lifted goods with in D+1.</li>
                                                        <li>Seller will receive 90% payment for delivered quantity with in D+1 after deducting agribazaar fee & other deductions if any.</li>
                                                        <li>EMD amount and remaining payment (if any) will be released to the Seller and Buyer at the time of final settlement only.</li>
                                                        <li>
                                                            The Seller will issue a credit note or the buyer will issue a debit note to the tune of quality discountif any, from the invoice value and share the same with agribazaar.
                                                            <br /> agribazaar will release payment to Seller only after receipt of such credit / debit note.
                                                        </li>
                                                        <li>Seller undertakes to make timely payment of GST, if applicable.</li>
                                                        <li>All payments for various third-party services availed by the Buyer or Seller will be released to the Service providers by agribazaar (on receipt of such amount from the Buyer or Seller) and invoices for the same will be provided to the respective parties.</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Delivery Condition</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div className=' pl-6'>
                                                    <ul className='list-disc'>
                                                        <li>Ex warehouse delivery at stock location.</li>
                                                        <li>Seller must offer 100% auctioned quantity just after auction approval and allow the buyer to lift the stock after getting instruction from agribazaar.</li>
                                                        <li>Buyer must lift the complete stock within stipulated time as mentioned in above table.</li>
                                                        <li>Any rejection and segregation by buyer will not be considered atthe time of delivery.</li>
                                                        <li>Seller will be responsible for all the charges of loading and storage charges (till the given timeline). Buyer will be responsible for all the charges of transportation, weighment and other if any.</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Transfer of title</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div className=' pl-6'>
                                                    <ul className='list-disc'>
                                                        <li>
                                                            The beneficial title and ownership of Commodities pursuant to this Contract Note shall pass from the Seller to the Buyer at the exact time, place and moment specified herein below. However, the risk of loss and damage will pass from the Seller to the Buyer once the stocks are loaded in trucks.
                                                            <p><strong>Time</strong>: Once the Buyer has acknowledged the invoice for the loaded stock at the seller’s location (mentioned below)</p>
                                                            <p><strong>Place</strong>: Port Name, Gujarat</p>
                                                            <p>Any other specifications</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Defaults</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div className=' pl-6'>
                                                    <ul className='list-disc'>
                                                        <li>In case the Buyer fails to make the payment within stipulated time then the EMD of the Buyer will be forfeited. The Buyer shall also be liable to pay a penalty equivalent to 1% of the Trade Value.</li>
                                                        <li>In case the Buyer fails to lift the complete trade quantity within stipulated time then trade will be cancelled (to the extent of non-lifted quantity) and the EMD of Buyer will be forfeited. The Buyer, additionally, will be liable to pay a penalty equivalent to the difference in the market prices between T+7 and T (agribazaar will use its discretion to determine market prices for the two dates, T and T+7) for the not lifted quantity [Price difference per MT * Non-lifted quantity in MT].</li>
                                                        <li>In case the Seller fails to offer the stock (whole or partial) with in stipulated time then trade will be cancelled and a penalty equivalent to EMD amount will be levied over seller and additionally price difference between T+7 or auction cancellation date& T (agribazaar will use its discretion to determine market price for the two dates) for non-offered quantity.</li>
                                                        <li>agribazaar fee of both sides for undelivered quantity will be deducted first from the forfeited EMD amount then 25% of remaining EMD amount will be retained by agribazaar and 75% will be paid to counter party.</li>
                                                        <li>Any deviation from the defined payment / delivery timelines will be at the discretion of agribazaar, with the consent of both the Buyer and Seller.</li>
                                                        <li>All deposits with agribazaar will not carry any interest.</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Force Majeure</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Should any of the force majeure circumstances, namely, act of God, natural calamity, fire, change in law or government policy, (excluding any stock limits), strikes or lockouts by workmen, war, military operations of any nature or blockades, prevent the Seller or the Buyer from wholly or partially carrying out their contractual obligations under this Contract Note, the period stipulated for the performance of this Contract Note shall be extended for as long as these circumstances prevail, provided that, in the event of these circumstances continuing for more than 15 Working Days, this Contract Note shall stand annulled and no penalty will be levied on either the Buyer or the Seller for non- performance of their obligations under this Contract Note.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Arbitration</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div className='leading-loose'>
                                                    <p>If any dispute, controversy or claim arises out of or in connection with this Contract Note, including any question regarding its existence, validity or termination arising out of or in connection with this Contract Note (a "Dispute"), the Parties shall use all reasonable endeavours to resolve the matter amicably. If one (1) Party gives another Party notice that a Dispute has arisen, and the Parties are unable to resolve the Dispute within fifteen (15) Working Days of service of the notice then the Dispute shall be referred to the senior executive officers of each of the Parties who shall attempt to resolve the Dispute. No Party shall resort to arbitration against the other Party under this Contract Note until fifteen (15) Working Days after such referral to a senior executive officer.</p>
                                                    <p>All Disputes, which are unresolved pursuant to the preceding clause and which a Party wishes to have resolved, shall be referred upon the application of any Party to and finally settled in accordance with the rules of Arbitration and Conciliation Act 1996 (as amended up to date) or any statutory amendments/modifications thereof for the time being in force at the date of this contract note ("Rules"). The number of arbitrators shall be three (3). One (1) arbitrator shall be appointed by the Buyer and one (1) arbitrator shall be appointed by the Seller, and together the two (2) arbitrators so appointed shall appoint the third (3rd) arbitrator.</p>
                                                    <p>The seat of the arbitration shall be Mumbai. The language of this arbitration shall be English. The courts in Mumbai shall have exclusive jurisdiction.</p>
                                                    <p>The arbitrators shall have the power to grant any legal or equitable remedy or relief available under Law, including injunctive relief (whether interim and/or final) and specific performance and any measures ordered by the arbitrators may be specifically enforced by any court of competent jurisdiction.</p>
                                                    <p>Any award of the arbitrator or arbitral tribunal, as the case may be, pursuant to this arbitration clause shall be in writing and shall be final, conclusive and binding upon the Parties, and the Parties shall be entitled (but not obliged) to enter judgment thereon in any one or more of the highest courts having jurisdiction.</p>
                                                    <p>During the course of any arbitration under this clause except for the matters under dispute, the Parties shall continue to exercise their remaining respective rights and fulfil their remaining respective obligations under this Contract Note.</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>General Terms and Conditions</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Eligibility: Buyers and Sellers should have all the required licenses and regulatory approvals to participate in the Auction</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li id="contentD2">
                            AUCTION CONTRACT NOTE – BUY SIDE
                            <div className='font-normal leading-loose'>
                                <p>This contract note sets out the specific terms in respect of a particular Auction conducted on the Platform and constitutes a binding agreement between the Buyer, the Seller and agribazaar (such parties, "Parties") (such agreement, "Contract Note"). This Contract Note needs to be read with and understood in light of the T&Cs and the Service Agreement. However, in the event of any inconsistency or contradiction between the specific terms of this Contract Note and the terms set out under the T&Cs and/or the Service Agreement, the terms of this Contract Note shall prevail and will be binding on the Parties hereto.</p>
                            </div>
                            <h2>Contract Specification</h2>
                            <div>
                                <table className="w-full table-fixed">
                                    <tbody className='border-separate '>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Commodity</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Maize</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Stock Quantity</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>100 MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Delivery Location</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>xxxxx, Erode (Dist.), Tamil Nadu – 638052</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Trading Unit</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>MT (Metric Tonnes)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Price Quote</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. per Quintal (exclusive of Goods and Services Tax ("GST") or any other taxes).</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Minimum Lot Size</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>25 MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Incremental Lot Size</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>25 MT</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Quantity Variation</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>± 5% of listed quantity.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Auction Type</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>2 Round (Yankee- Buy)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Tick Size</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. 1</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Reserve Price</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Maximum price above which the Seller cannot bid.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Auction Date Time</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Date (Month DD, YYYY) (referred to herein after as "T") and time slot (HH:MM) as stated in the auction listing on the Platform. There will be no extensions in the auction duration.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Seller EMD</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. 750 per MT (approx. 5%)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Buyer EMD</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Rs. 750 per MT (approx. 5%)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Bidding Rules</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>Sellers can quote for any quantity up to maximum auctioned quantity.</li>
                                                    <li>Sellers can revise their quantity subject to given lot size.</li>
                                                    <li>Sellers can revise their bids downwards and only latest bid will be considered.</li>
                                                    <li>The auction runs on Price Quantity Time priority (PQT)</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Winning Bid/ Auction Confirmation</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>All the bidders with their bid price less than the reserve price in round 1 will be auto approved till the availability of quantity.</li>
                                                    <li>After round 1, buyer will be given 15 minutes time to revise his reserve price upwards.</li>
                                                    <li>Round 2 is price matching round, where bidders will be asked to match with the reserve price with the quantity allotted by the system.</li>
                                                    <li>Post auction, confirmation e-mail will be sent to both parties with buyer and seller details.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Gross/Net wt. basis</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Price Quote is on Gross Weight basis</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>GST/ Other Taxes</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div>i) GST shall be paid by buyer, as applicable, in addition to the price quote. The GST amount will be released by agribazaar to the Seller upon receipt of confirmation from the Buyer.</div>
                                                <div>ii) Mandi Cess will be paid by seller, if applicable. The receipt of paid Mandi Cess will be required by the Buyer along with the invoice.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Packaging</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>50/60 Kg. Jute Bags</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Trade Value</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Commodity Value [Stock Quantity * Winning Bid] + GST/Other taxes (if applicable)</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>agribazaar service fee</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div>Seller-0.25% of Commodity Value + GST (18%)</div>
                                                <div>Buyer- As set out in the Corporate Agreement</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Payment Gateway</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                All payments shall be done through the agribazaar Unique Account Number
                                                <div className='text-md font-medium'>
                                                    <p><strong>Payee Name</strong> – Star Agribazaar Technology Private Limited</p>
                                                    <p><strong>Account No (Unique to each User)</strong> – ZAGBZRXXXXXXXXXXXX</p>
                                                    <p><strong>Bank</strong> – IndusInd Bank</p>
                                                    <p><strong>IFSC</strong> – INDB0000018</p>
                                                    <p><strong>Bank Branch</strong> – Andheri, Mumbai</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Quality Parameters and variation</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D]'>
                                                <ul className='list-disc pl-4'>
                                                    <li>Buyer’s lab will conduct testing of the stock at the time of inward, deduction & rejections will be done as per the below quality matrix. The cost of such testing will be borne by the Buyer.</li>
                                                    <li>A third-party assayer will be assigned for testing the goods if the seller does not agree with the report from the buyer’s lab</li>
                                                    <li>If the stocks are rejected based on the final quality report, then the seller has to replace the stocks within stipulated time or the trade will be cancelled and EMD of the Seller will be forfeited.</li>
                                                </ul>
                                                <div>
                                                    <table className='border-2 border-collapse'>
                                                        <thead >
                                                            <tr className='border-2 border-collapse'>
                                                                <th className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2 w-1/4'>Parameter</th>
                                                                <th className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2 w-1/4'>Basis Value</th>
                                                                <th className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2 w-1/4'>Tolerance & Discount Matrix</th>
                                                                <th className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2 w-1/4'>Rejection Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Moisture</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>14%</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'></td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>&gt;14.0%</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Foreign Matter</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1%</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1.1% to 2.0% - 1:1</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>&gt;2.0%</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Fungus</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1%</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1.1% to 3.0% - 1:1</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>&gt;3.0%</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Damaged/Broken</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1%</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1.1% to 2.0% - 1:1</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>&gt;2.0%</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Immature & Shrivelled</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>1%</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'></td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>&gt;1.0%</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Insect Infestation</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>0%</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'></td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>&gt;0%</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>Count per 100 gms</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>350</td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'></td>
                                                                <td className='text-xs sm:text-sm break-words border-2 border-collapse sm:p-2'>370</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Timeline for payment & delivery</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <div>
                                                    <table className='border-2 border-collapse'>
                                                        <thead >
                                                            <tr className='border-2 border-collapse'>
                                                                <th className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Min. Deliverable Quantity (MT) for Seller(s)</th>
                                                                <th className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Max. Delivery Period (W/H Working Days) for Seller(s)</th>
                                                                <th className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Payment timeline (Bank working days) for Buyer</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>15% (of bid quantity) or 50 MT whichever is higher</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>T + 4 days</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>D + 2 days</td>
                                                            </tr>
                                                            <tr className='border-2 border-collapse'>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>Balance Quantity</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>T + 7 days</td>
                                                                <td className='text-xs sm:text-sm break-words pl-2 border-2 border-collapse p-2'>D + 2 days</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className=' pl-6'>
                                                    <ul className='list-disc'>
                                                        <li>"T" is date of auction.</li>
                                                        <li>"D" is date of successful delivery. D is less than or equal to the maximum delivery period.</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Payment terms</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>
                                                        Buyer will have to release payment to agribazaar settlement account for the received stock as per above mentioned timelines. The payment will include the following:
                                                        <div className='pl-4'>
                                                            <p>1. (Stock Quantity*Winning Bid) minus quality deductions, if any</p>
                                                            <p>2. GST and any other tax to be deposited by the Buyer, if applicable</p>
                                                            <p>3. agribazaar service fee</p>
                                                            <p>4. Charges for any other services availed.</p>
                                                        </div>
                                                    </li>
                                                    <li>Seller will receive the payment for delivered quantity with in D+2 days after deducting quality deductions, agribazaar fee & other deductions if any.</li>
                                                    <li>EMD amount will be released to the Seller at the time of final settlement only.</li>
                                                    <li>The Seller will issue a credit note or the buyer will issue a debit note to the tune of quality discount, if any, from the invoice value and share the same with agribazaar. agribazaar will release payment to Seller only after receipt of such credit note(s).</li>
                                                    <li>Seller undertakes to make timely payment of GST, if applicable.</li>
                                                    <li>All the payments for various third-party services availed by the Buyer or Seller will be released to the Service providers by agribazaar (on receipt of such amount from the Buyer or Seller) and invoices for the same will be provided to the respective parties.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Delivery Condition</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>FOR delivery at location.</li>
                                                    <li>Seller must deliver 100% auctioned quantity within stipulated period.</li>
                                                    <li>Any rejected quantity will not be considered as delivered stock.</li>
                                                    <li>Seller will be responsible for all the charges including transportation, weighment, till the delivery Point.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Transfer of title</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>
                                                        The beneficial title and ownership of Commodities pursuant to this Contract Note shall pass from the Seller to the Buyer at the exact time, place and moment specified herein below. However, the risk of loss and damage will pass from the Seller to the Buyer once the stocks are unloaded at the Delivery location.
                                                        <div className='pl-2'>
                                                            <p>1. <strong>Time</strong>: Once the Buyer has acknowledged the invoice for the delivered stock at the buyer’s location (mentioned below)</p>
                                                            <p>2. <strong>Place</strong>: xxxxxx, Erode (Dist.) Tamil Nadu - 638052</p>
                                                            <p>3. <strong>Any other specifications</strong></p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Defaults</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-6'>
                                                <ul className='list-disc'>
                                                    <li>In case the Buyer fails to make the payment for delivered stock within stipulated time then the EMD of the Buyer will be forfeited. The Buyer shall also be liable to pay a penalty equivalent to 1% of the Trade Value.</li>
                                                    <li>In case the seller fails to deliver the complete trade quantity within stipulated time then trade will be cancelled (to the extent of undelivered quantity) and the EMD of Seller will be forfeited. The Seller, additionally, will be liable to pay a penalty equivalent to the difference in the market prices between T+7 and T (agribazaar will use its discretion to determine market prices for the two dates, T and T+7) for the undelivered quantity [Price difference per MT * Undelivered quantity in MT].</li>
                                                    <li>agribazaar fee of both sides for undelivered quantity will be deducted first from the forfeited EMD amount then 25% of remaining EMD amount will be retained by agribazaar and 75% will be paid to counter party.</li>
                                                    <li>Any deviation from the defined payment / delivery timelines will be at the discretion of agribazaar, with the consent of both the Buyer and Seller.</li>
                                                    <li>All deposits with agribazaar will not carry any interest.</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Force Majeure</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Should any of the force majeure circumstances, namely, act of God, natural calamity, fire, change in law or government policy, (excluding any stock limits), strikes or lockouts by workmen, war, military operations of any nature or blockades, prevent the Seller or the Buyer from wholly or partially carrying out their contractual obligations under this Contract Note, the period stipulated for the performance of this Contract Note shall be extended for as long as these circumstances prevail, provided that, in the event of these circumstances continuing for more than 15 Working Days, this Contract Note shall stand annulled and no penalty will be levied on either the Buyer or the Seller for non- performance of their obligations under this Contract Note.</td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>Arbitration</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>
                                                <p>If any dispute, controversy or claim arises out of or in connection with this Contract Note, including any question regarding its existence, validity or termination arising out of or in connection with this Contract Note (a "Dispute"), the Parties shall use all reasonable endeavours to resolve the matter amicably. If one (1) Party gives another Party notice that a Dispute has arisen, and the Parties are unable to resolve the Dispute within fifteen (15) Working Days of service of the notice then the Dispute shall be referred to the senior executive officers of each of the Parties who shall attempt to resolve the Dispute. No Party shall resort to arbitration against the other Party under this Contract Note until fifteen (15) Working Days after such referral to a senior executive officer.</p>
                                                <p>All Disputes, which are unresolved pursuant to the preceding clause and which a Party wishes to have resolved, shall be referred upon the application of any Party to and finally settled in accordance with the rules of Arbitration and Conciliation Act 1996 (as amended up to date) or any statutory amendments/modifications thereof for the time being in force at the date of this contract note ("Rules"). The number of arbitrators shall be three (3). One (1) arbitrator shall be appointed by the Buyer and one (1) arbitrator shall be appointed by the Seller, and together the two (2) arbitrators so appointed shall appoint the third (3rd) arbitrator.</p>
                                                <p>The seat of the arbitration shall be Mumbai. The language of this arbitration shall be English. The courts in Mumbai shall have exclusive jurisdiction.</p>
                                                <p>The arbitrators shall have the power to grant any legal or equitable remedy or relief available under Law, including injunctive relief (whether interim and/or final) and specific performance and any measures ordered by the arbitrators may be specifically enforced by any court of competent jurisdiction.</p>
                                                <p>Any award of the arbitrator or arbitral tribunal, as the case may be, pursuant to this arbitration clause shall be in writing and shall be final, conclusive and binding upon the Parties, and the Parties shall be entitled (but not obliged) to enter judgment thereon in any one or more of the highest courts having jurisdiction.</p>
                                                <p>During the course of any arbitration under this clause except for the matters under dispute, the Parties shall continue to exercise their remaining respective rights and fulfil their remaining respective obligations under this Contract Note.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-sm break-words pl-2 w-[20%] border-2 border-[#F7D39D]'>General Terms and Conditions</td>
                                            <td className='text-sm sm:text-base p-2 w-[80%] border-2 border-[#F7D39D] pl-2'>Eligibility: Buyers and Sellers should have all the required licenses and regulatory approvals to participate in the Auction</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
    //main body
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full px-4 sm:px-8 text-[#3B3939] text-center sm:text-left pb-20 sm:pt-4'>
                <div id="wrapper" className=' flex flex-col sm:flex-row justify-center items-start gap-2'>
                    <div id="left-menu" className='w-full sm:w-1/4 text-center sm:text-left sticky px-4 sm:px-0 h-full sm:h-[100vh] overflow-y-auto'>
                        <DiscloseWText title="Table of Contents" content={tableOfContents()} isDefaultOpen={true} manuallyCloseMenu={isManualCloseMenu} handleDiscloseBtnClick={() => setTimeout(() => setisManualCloseMenu(true))} />
                    </div >
                    <div id="right-content" className='w-full h-full sm:w-3/4 text-center sm:text-left sm:pl-4 px-2'>
                        <div className='w-full'>
                            {
                                activeComponent === "preamble" ? preambleContent() :
                                    activeComponent === "defAndInter" ? defAndInterContent() :
                                        activeComponent === "partA" ? partAContent() :
                                            activeComponent === "partB" ? partBContent() :
                                                activeComponent === "partC" ? partCContent() :
                                                    activeComponent === "partD" ? partDContent() :
                                                        (
                                                            <Fragment>
                                                                {preambleContent()}
                                                                {defAndInterContent()}
                                                            </Fragment>
                                                        )
                            }
                        </div>
                        {scrollToTopBtn()}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <QuickLinksTemp currentPageTitle={TermsOfUseObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}