const appConfig = {
  environment: "PRODUCTION",
  domain: {
    PRODUCTION: {
      agribazaar: "https://api-prod.agribazaar.com",
      blog: "https://blog-prod.agribazaar.com",
      noderefresh: "https://api2-prod.agribazaar.com",
    },
    UAT: {
      agribazaar: "https://api-uat.agribazaar.com",
      blog: "https://blog-uat.agribazaar.com",
      noderefresh: "https://api2-uat.agribazaar.com",
    },
  },
  route: {
    PRODUCTION: "https://trade-prod.agribazaar.com",
    UAT: "https://trade-uat.agribazaar.com",
  },
  marketPlace: {
    PRODUCTION: "https://marketplace.agribazaar.com/",
    UAT: "https://uatmarketplace.agribazaar.com/"
  },
  blog: {
    PRODUCTION: "https://blog-prod.agribazaar.com",
    UAT: "https://blog-uat.agribazaar.com"
  }
};

export default appConfig;
