import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Footer from "./components/Footer";
import ContactUs from "./pages/ContactUs";
import FoundersMessage from "./pages/AboutAgribazaar/FoundersMessage";
import OurStory from "./pages/AboutAgribazaar/OurStory";
import OurTeam from "./pages/AboutAgribazaar/OurTeam";
import OurVision from "./pages/AboutAgribazaar/OurVision";
import Agribhumi from "./pages/OurProducts/Agribhumi";
import Agriknow from "./pages/OurProducts/Agriknow";
import Agripay from "./pages/OurProducts/Agripay";
import Emandi from "./pages/OurProducts/Emandi";
import InputMarketplace from "./pages/OurProducts/InputMarketplace";
import KisanCreditCard from "./pages/OurProducts/KisanCreditCard";
import Marketplace from "./pages/OurProducts/Marketplace";
import OnlineAuctionEngine from "./pages/OurProducts/OnlineAuctionEngine";
import AgriFintech from "./pages/OurServices/AgriFintech";
import CropAssessment from "./pages/OurServices/CropAssessment";
import InputOutputMarketplace from "./pages/OurServices/InputOutputMarketplace";
import IntelGoodSettlementServices from "./pages/OurServices/IntelGoodSettlementServices";
import IOTFarmManagement from "./pages/OurServices/IOTFarmManagement";
import AdminLoginRedirector from "./components/AdminLoginRedirector";
import Steps from "./pages/QuickLinks/Steps";
import FAQs from "./pages/QuickLinks/FAQs";
import TermsOfUse from "./pages/QuickLinks/TermsOfUse";
import PressAndMedia from "./pages/QuickLinks/PressAndMedia";
import PeoplePhilosophy from "./pages/Careers/PeoplePhilosophy";
import Blog from "./pages/AgriInsights/Blog";
import AgriTalk from "./pages/AgriInsights/AgriTalk";
import AgriInsights from "./pages/AgriInsights/AgriInsights";
import SaleOfNafedPSS from "./pages/AgriInsights/SaleOfNafedPSS";
import WhyAgribazaar from "./pages/Careers/WhyAgribazaar";
import CareerOpportunities from "./pages/Careers/CareerOpportunities";
import ApplyForJob from "./components/ReusableGeneric/ApplyForJob";
import InputMarketplaceStoreHippo from "./pages/InputMarketplaceStoreHippo";
// import BlogsAndArticles from "./components/ReusableGeneric/BlogsAndArticles";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {

  return (
    <BrowserRouter>
      <div className="bg-white">
        <Navbar />
        <Routes>
        {/* below line to be commented during downtime */}
          <Route path="/" element={<Home />} exact />   
        {/* below line to be uncommented during downtime/to show downtime page */}
          {/* <Route path="/" element={<Error />} exact /> */}
          <Route path="/adminlogin" element={<AdminLoginRedirector />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/founder-message" element={<FoundersMessage />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/our-vision" element={<OurVision />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/our-products/agribhumi" element={<Agribhumi />} />
          <Route path="/our-products/input-marketplace" element={<InputMarketplace />} />
          <Route path="/our-products/online-auction-engine" element={<OnlineAuctionEngine />} />
          <Route path="/our-products/e-mandi" element={<Emandi />} />
          <Route path="/our-products/agriknow" element={<Agriknow />} />
          <Route path="/our-products/agripay" element={<Agripay />} />
          <Route path="/our-products/marketplace" element={<Marketplace />} />
          <Route path="/our-products/kisan-credit-card" element={<KisanCreditCard />} />
          <Route path="/our-services/iot-based-farm-management" element={<IOTFarmManagement />} />
          <Route path="/our-services/crop-assessment" element={<CropAssessment />} />
          <Route path="/our-services/goods-and-settlement" element={<IntelGoodSettlementServices />} />
          <Route path="/our-services/input-output-marketplace" element={<InputOutputMarketplace />} />
          <Route path="/our-services/agri-fintech-services" element={<AgriFintech />} />

          <Route path="/quick-links/steps" element={<Steps />} />
          <Route path="/quick-links/faq" element={<FAQs />} />
          <Route path="/quick-links/terms-of-use" element={<TermsOfUse />} />
          <Route path="/careers/people-philosophy" element={<PeoplePhilosophy />} />
          <Route path="/careers/why-agribazaar" element={<WhyAgribazaar />} />
          <Route path="/quick-links/press-and-media" element={<PressAndMedia />} />
          <Route path="/press-release" element={<PressAndMedia />} />
          <Route path="/agri-insights/blog" element={<Blog />} />
          <Route path="/agri-insights/agriTalk" element={<AgriTalk />} />
          <Route path="/newsletters" element={<AgriTalk />} />
          <Route path="/agri-insights/agriInsights" element={<AgriInsights />} />
          <Route path="/commodity-outlook" element={<AgriInsights />} />
          <Route path="/agri-insights/sale-of-nafed-pss" element={<SaleOfNafedPSS />} />
          {/* <Route path="/careers/career-opportunities" element={<CareerOpportunities />} /> */}
          {/* <Route path="/careers/career-opportunities/apply" element={<ApplyForJob />} /> */}
          {/* <Route path="/imp" element={<InputMarketplaceStoreHippo />}/> */}
          {/* <Route path="/blogs-articles" element={<BlogsAndArticles />} /> */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;