
const aboutAtul = () => {
    return (
        <div>
            <div>
            Atul Chhura is the Chief Business Officer at agribazaar. An advocate of the UN’s SDG vision to make agriculture more sustainable, Atul wants to reshape the Indian agriculture narrative by empowering small farmers with technology. He works closely with policymakers and businesses to equip farmers with resilient agricultural practices that strengthen food production systems.
            </div>
            <div>
            Armed with over two decades of experience and deep insights, Atul has worked extensively to expand the reach of technology in Indian agriculture. He spent almost a decade working with StarAgri Warehousing & Collateral Management where he was Vice President of Research & Planning. Before that, he was with Feedback Business Consulting, a leading market research and advisory company, for a decade.
            </div>
            <div>
            Atul has a BTech degree in Electrical & Electronics Engineering from Giani Zail Singh Campus College of Engineering & Technology, Punjab, India.
            </div>

        </div>
    )
}

export default aboutAtul();