
const aboutAmitMun = () => {
    return (
        <div>
            <div>
            Amit Mundawala is the Co-founder and Director of agribazaar. He believes technology has the power to integrate the complexities of agribusiness into a seamless ecosystem. The accomplished agripreneur co-founded agribazaar with the vision to revolutionise agribusiness in India. His efforts are concentrated on infusing operational excellence into the agri value chain to create a framework that is robust and efficient.
            </div>
            <div>
            Amit has a solid grasp of the Indian agricultural sector and has worked closely with all stakeholders in the ecosystem. He has built businesses that empower small farm holders and has first-hand experience in strengthening India’s post-harvest value chain. Amit also co-founded StarAgri Warehousing & Collateral Management.
            </div>
            <div>
            He graduated from Satyawati College, New Delhi, India and holds a Master’s degree in English Literature from MD College, Rajasthan, India.
            </div>
        </div>
    )
}

export default aboutAmitMun();